const date = new Date();

export const Year = date.getFullYear();
export const Month = Number(date.getMonth()) + 1;
export const Day = date.getDate();

/**
 * Fetchの例外
 */
export const CatchError = {
	type: 'danger',
	title: '通信エラー',
	abstract: 'ERROR',
	message: '通信エラーが発生しました',
	timeout: 0,
};

export const AppName = 'LiCSSs家計簿';
