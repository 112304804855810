import { createContext } from 'react';

const SettingContext = createContext({
	Basic: null,
	Income: [],
	Payment: [],
	Category: [],
	Item: {},
	Store: [],
	BasicObject: {},
	IncomeObject: {},
	PaymentObject: {},
	CategoryObject: {},
	ItemObject: {},
	StoreObject: {},
	setBasic: () => {},
	setIncome: () => {},
	setPayment: () => {},
	setCategory: () => {},
	setItem: () => {},
	setStore: () => {},
});

export default SettingContext;
