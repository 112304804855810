import ConvertText from './../ConvertText';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './style.css';

export default function LoadMd({ markdown }) {
	return (
		<ReactMarkdown className='markdown-wrapper' children={markdown} remarkPlugins={[remarkGfm]} />
	);
}
