import { Accordion, Button, ButtonGroup, Col, Form, InputGroup, ListGroup, Row, Tab, Tabs } from 'react-bootstrap';
import { useContext, useEffect, useRef, useState } from 'react';
import AccountsContext from '../../common/contexts/AccountsContext';
import BreadCrumbContext from '../../common/contexts/BreadCrumbContext';
import InfoContext, { ErrorContext } from '../../common/contexts/InfoContext';
import LoadingContext from '../../common/contexts/LoadingContext';
import { changeTitle, createKey } from '../../components/functional';
import Fetch from '../../components/functional/Fetch';
import Input from '../../components/ui-elements/Input';
import setMessage from '../../components/functional/setMessage';
import SettingContext from '../../custom/contexts/SettingContext';
import './style.css';

const TabClassName = 'border border-top-0 p-3';

export default function Setting() {
	changeTitle('設定');
	const breadCrumb = useContext(BreadCrumbContext);
	const Accounts = useContext(AccountsContext);
	const Info = useContext(InfoContext);
	const Error = useContext(ErrorContext);
	const Loading = useContext(LoadingContext);
	const Setting = useContext(SettingContext);
	const setMes = new setMessage(Info, Error);
	const [Validated, setValidated] = useState(false);

	useEffect(() => {
		breadCrumb['changeVisible'](true);
		breadCrumb['setItems']([]);
	}, []);
	/**
	 * submit直後
	 * If validation is ok, return `true`.
	 * If validation errors occurs, return `false`.
	 *
	 * @param {*} e
	 * @return boolean
	 */
	function actionSubmit(e, AllProcess = 2, form = null) {
		e.preventDefault();
		Loading['Init'](AllProcess);
		if (form === null) {
			form = e.target;
		}
		if (!form.checkValidity()) {
			//バリデーションエラー
			setValidated(true);
			Loading['setLoading'](false);
			return false;
		}
		Loading['AddProcess']();
		return true;
	}
	/**
	 * submit終了後
	 *
	 */
	function doneSubmit(MessageObject = { title: '変更完了' }) {
		Loading['AddProcess']();
		Loading['setLoading'](false);
		setMes.addSuccess(MessageObject);
		setValidated(false);
	}
	function doneSubmitError(error) {
		Loading['setLoading'](false);
		setMes.addError(error);
	}
	function doneSubmitNetError(error) {
		Loading['setLoading'](false);
		setMes.addNetError(error);
	}
	//基本
	function submitBasic(e) {
		if (!actionSubmit(e, 3)) {
			return;
		}
		let body = [];
		let inputs = e.target.getElementsByClassName('js-data');
		for (let i = 0; i < inputs.length; ++i) {
			body.push({
				type: inputs[i].name,
				value: inputs[i].value / 100,
			});
		}
		Loading['AddProcess']();
		Fetch({ path: 'account/setting', accountId: Accounts['CurrentAccount']['AccountId'], method: 'PUT', body: body })
			.then((data) => {
				if (data['result']) {
					Setting['setBasic'](data['payloads']);
					doneSubmit({ title: '【基本設定】変更完了' });
				} else {
					doneSubmitError(data['error']);
				}
			})
			.catch((error) => {
				doneSubmitNetError(error);
			});
	}
	//収入元
	function addIncome(e) {
		if (!actionSubmit(e, 2)) {
			return;
		}
		Fetch({
			path: 'account/setting/income',
			accountId: Accounts['CurrentAccount']['AccountId'],
			method: 'POST',
			body: { name: e.target[0].value },
		})
			.then((data) => {
				if (data['result']) {
					Setting['setIncome'](Setting['Income'].concat(data['payloads']));
					doneSubmit({ title: '【収入元】追加完了' });
					e.target[0].value = '';
					e.target[0].focus();
				} else {
					doneSubmitError(data['error']);
				}
			})
			.catch((error) => {
				doneSubmitNetError(error);
			});
	}
	function editIncome(e) {
		if (!actionSubmit(e, 2, e.target.form)) {
			return;
		}
		let send = {
			path: `account/setting/income/${e.target.dataset.id}`,
			accountId: Accounts['CurrentAccount']['AccountId'],
			method: 'DELETE',
		};
		if (e.target.value === 'edit') {
			send['body'] = { name: e.target.parentNode.parentNode.parentNode.getElementsByClassName('js-data')[0].value };
			send['method'] = 'PUT';
		}
		Fetch(send)
			.then((data) => {
				if (data['error']) {
					doneSubmitError(data['error']);
				} else {
					doneSubmit({ title: '【収入元】' + (e.target.value === 'edit' ? '変更' : '削除') + '完了' });
					let newItem = Setting['Income'].concat();
					if (e.target.value === 'edit') {
						newItem[e.target.dataset.idx] = data['payloads'];
					} else {
						newItem.splice(e.target.dataset.idx, 1);
					}
					Setting['setIncome'](newItem.concat());
				}
			})
			.catch((error) => {
				doneSubmitNetError(error);
			});
	}
	//支払い方法
	function changeAccounted(e) {
		if (e.target.value == 0) {
			e.target.parentNode.classList.remove('setting-payment__accounted');
		} else {
			e.target.parentNode.classList.add('setting-payment__accounted');
		}
	}
	function addPayment(e) {
		if (!actionSubmit(e, 3)) {
			return;
		}
		let send = {
			path: 'account/payment',
			accountId: Accounts['CurrentAccount']['AccountId'],
			method: 'POST',
			body: {},
		};
		let inputs = e.target.getElementsByClassName('js-data');
		for (let i = 0; i < inputs.length; ++i) {
			send['body'][inputs[i].name] = inputs[i].name !== 'name' ? Number(inputs[i].value) : inputs[i].value;
		}
		Loading['AddProcess']();
		Fetch(send)
			.then((data) => {
				if (data['result']) {
					Setting['setPayment'](Setting['Payment'].concat(data['payloads']));
					doneSubmit({ title: '【支払い方法】追加完了' });
					inputs[0].value = '';
					inputs[0].focus();
				} else {
					doneSubmitError(data['error']);
				}
			})
			.catch((error) => {
				doneSubmitNetError(error);
			});
	}
	function editPayment(e) {
		if (!actionSubmit(e, 3, e.target.form)) {
			return;
		}
		let send = {
			path: `account/payment/${e.target.dataset.id}`,
			accountId: Accounts['CurrentAccount']['AccountId'],
			method: 'DELETE',
		};
		if (e.target.value === 'edit') {
			let inputs = e.target.parentNode.parentNode.parentNode.parentNode.getElementsByClassName('js-data');
			send['body'] = {};
			for (let i = 0; i < inputs.length; ++i) {
				send['body'][inputs[i].name] = inputs[i].name !== 'name' ? Number(inputs[i].value) : inputs[i].value;
			}
			send['method'] = 'PUT';
		}
		Loading['AddProcess']();
		Fetch(send)
			.then((data) => {
				if (data['error']) {
					doneSubmitError(data['error']);
				} else {
					doneSubmit({ title: '【支払い方法】' + (e.target.value === 'edit' ? '変更' : '削除') + '完了' });
					let newItem = Setting['Payment'].concat();
					if (e.target.value === 'edit') {
						newItem[e.target.dataset.idx] = data['payloads'];
					} else {
						newItem.splice(e.target.dataset.idx, 1);
					}
					Setting['setPayment'](newItem);
				}
			})
			.catch((error) => {
				doneSubmitNetError(error);
			});
	}
	//カテゴリー
	function addCategory(e) {
		if (!actionSubmit(e, 3)) {
			return;
		}
		let send = {
			path: 'account/category',
			accountId: Accounts['CurrentAccount']['AccountId'],
			method: 'POST',
			body: {},
		};
		let inputs = e.target.getElementsByClassName('js-data');
		for (let i = 0; i < inputs.length; ++i) {
			send['body'][inputs[i].name] = inputs[i].value;
		}
		Loading['AddProcess']();
		Fetch(send)
			.then((data) => {
				if (data['result']) {
					Setting['setCategory'](Setting['Category'].concat(data['payloads']));
					let adds = {};
					adds[data['payloads']['CategoryId']] = [];
					Setting['setItem'](Object.assign(Setting['Item'], adds));
					doneSubmit({ title: '【カテゴリー】追加完了' });
					inputs[0].value = '';
					inputs[1].value = '';
					inputs[0].focus();
				} else {
					doneSubmitError(data['error']);
				}
			})
			.catch((error) => {
				doneSubmitNetError(error);
			});
	}
	function editCategory(e) {
		if (!actionSubmit(e, 3, e.target.form)) {
			return;
		}
		let send = {
			path: `account/category/${e.target.dataset.id}`,
			accountId: Accounts['CurrentAccount']['AccountId'],
			method: 'DELETE',
		};
		if (e.target.value === 'edit') {
			let inputs = e.target.parentNode.parentNode.parentNode.parentNode.getElementsByClassName('js-data');
			send['body'] = {};
			for (let i = 0; i < inputs.length; ++i) {
				send['body'][inputs[i].name] = inputs[i].value;
			}
			send['method'] = 'PUT';
		}
		Loading['AddProcess']();
		Fetch(send)
			.then((data) => {
				if (data['error']) {
					doneSubmitError(data['error']);
				} else {
					doneSubmit({ title: '【カテゴリー】' + (e.target.value === 'edit' ? '変更' : '削除') + '完了' });
					let newItem = Setting['Category'].concat();
					if (e.target.value === 'edit') {
						newItem[e.target.dataset.idx] = data['payloads'];
					} else {
						newItem.splice(e.target.dataset.idx, 1);
					}
					Setting['setCategory'](newItem);
				}
			})
			.catch((error) => {
				doneSubmitNetError(error);
			});
	}
	//支出品目
	function addItem(e) {
		if (!actionSubmit(e, 3)) {
			return;
		}
		let send = {
			accountId: Accounts['CurrentAccount']['AccountId'],
			method: 'POST',
			body: {},
		};
		let inputs = e.target.getElementsByClassName('js-data');
		for (let i = 0; i < inputs.length; ++i) {
			let values;
			switch (inputs[i].name) {
				case 'price':
				case 'tax':
					values = Number(inputs[i].value);
					break;
				case 'taxIncluded':
					values = inputs[i].value == 'true' ? true : false;
					break;
				default:
					values = inputs[i].value;
					break;
			}
			send['body'][inputs[i].name] = values;
		}
		if (send['body']['CategoryId']) {
			send['path'] = `account/category/${send['body']['CategoryId']}/item`;
		} else {
			doneSubmitError({});
		}
		Loading['AddProcess']();
		Fetch(send)
			.then((data) => {
				if (data['result']) {
					let adds = {};
					adds[data['payloads']['CategoryId']] = Setting['Item'][data['payloads']['CategoryId']].concat(
						data['payloads']
					);
					Setting['setItem'](Object.assign(Setting['Item'], adds));
					/*
					let catId = Setting['CategoryObject'][data['payloads']['CategoryId']]['idx'];
					let catAdds = {};
					catAdds[catId]=Setting['Category'][catId]['items'].concat(data['payloads']);
					Setting['setCategory'](Object.assign(Setting['Category'],catAdds));*/
					doneSubmit({ title: '【支出品目】追加完了' });
					inputs[0].value = '';
					inputs[2].value = '';
					inputs[5].value = '';
					inputs[0].focus();
				} else {
					doneSubmitError(data['error']);
				}
			})
			.catch((error) => {
				doneSubmitNetError(error);
			});
	}
	function editItem(e) {
		if (!actionSubmit(e, 3, e.target.form)) {
			return;
		}
		let send = {
			path: `account/category/${e.target.dataset.cat}/item/${e.target.dataset.id}`,
			accountId: Accounts['CurrentAccount']['AccountId'],
			method: 'DELETE',
		};
		if (e.target.value === 'edit') {
			let inputs = e.target.parentNode.parentNode.parentNode.parentNode.getElementsByClassName('js-data');
			send['body'] = {};
			for (let i = 0; i < inputs.length; ++i) {
				let values;
				switch (inputs[i].name) {
					case 'price':
					case 'tax':
						values = Number(inputs[i].value);
						break;
					case 'taxIncluded':
						values = inputs[i].value == 'true' ? true : false;
						break;
					default:
						values = inputs[i].value;
						break;
				}
				send['body'][inputs[i].name] = values;
			}
			send['method'] = 'PUT';
		}
		Loading['AddProcess']();
		Fetch(send)
			.then((data) => {
				if (data['error']) {
					doneSubmitError(data['error']);
				} else {
					doneSubmit({ title: '【支出品目】' + (e.target.value === 'edit' ? '変更' : '削除') + '完了' });
					let newItem = Object.assign({}, Setting['Item']);
					if (e.target.value === 'edit') {
						newItem[e.target.dataset.cat][e.target.dataset.idx] = data['payloads'];
					} else {
						newItem[e.target.dataset.cat].splice(e.target.dataset.idx, 1);
					}
					Setting['setItem'](Object.assign({}, newItem));
				}
			})
			.catch((error) => {
				doneSubmitNetError(error);
			});
	}
	//支払先
	function addStore(e) {
		if (!actionSubmit(e, 3)) {
			return;
		}
		let send = {
			path: 'account/store',
			accountId: Accounts['CurrentAccount']['AccountId'],
			method: 'POST',
			body: {},
		};
		let inputs = e.target.getElementsByClassName('js-data');
		for (let i = 0; i < inputs.length; ++i) {
			send['body'][inputs[i].name] =
				inputs[i].name === 'taxIncluded' ? (inputs[i].value == 'true' ? true : false) : inputs[i].value;
		}
		Loading['AddProcess']();
		Fetch(send)
			.then((data) => {
				if (data['result']) {
					Setting['setStore'](Setting['Store'].concat(data['payloads']));
					doneSubmit({ title: '【支出先】追加完了' });
					inputs[0].value = '';
					inputs[3].value = '';
					inputs[0].focus();
				} else {
					doneSubmitError(data['error']);
				}
			})
			.catch((error) => {
				doneSubmitNetError(error);
			});
	}
	function editStore(e) {
		if (!actionSubmit(e, 3, e.target.form)) {
			return;
		}
		let send = {
			path: `account/store/${e.target.dataset.id}`,
			accountId: Accounts['CurrentAccount']['AccountId'],
			method: 'DELETE',
		};
		if (e.target.value === 'edit') {
			let inputs = e.target.parentNode.parentNode.parentNode.parentNode.getElementsByClassName('js-data');
			send['body'] = {};
			for (let i = 0; i < inputs.length; ++i) {
				send['body'][inputs[i].name] =
					inputs[i].name === 'taxIncluded' ? (inputs[i].value == 'true' ? true : false) : inputs[i].value;
			}
			send['method'] = 'PUT';
		}
		Loading['AddProcess']();
		Fetch(send)
			.then((data) => {
				if (data['error']) {
					doneSubmitError(data['error']);
				} else {
					doneSubmit({ title: '【支払先】' + (e.target.value === 'edit' ? '変更' : '削除') + '完了' });
					let newItem = Setting['Store'].concat();
					if (e.target.value === 'edit') {
						newItem[e.target.dataset.idx] = data['payloads'];
					} else {
						newItem.splice(e.target.dataset.idx, 1);
					}
					Setting['setStore'](newItem);
				}
			})
			.catch((error) => {
				doneSubmitNetError(error);
			});
	}

	return (
		<>
			<Tabs defaultActiveKey="basic" id="_setting_tabs" fill>
				<Tab eventKey="basic" title="基本" className={TabClassName}>
					{Setting['Basic'] ? (
						<Form noValidate validated={Validated} onSubmit={submitBasic}>
							<Form.Group as={Row} className="mb-3">
								<Form.Label column sm="auto">
									標準税率
								</Form.Label>
								<Col sm="auto">
									<InputGroup>
										<Form.Control
											type="number"
											placeholder="標準税率"
											name="0"
											className="js-data"
											defaultValue={Setting['Basic'][0]['value'] * 100}
											required
										/>
										<InputGroup.Text>%</InputGroup.Text>
									</InputGroup>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="mb-3">
								<Form.Label column sm="auto">
									軽減税率
								</Form.Label>
								<Col sm="auto">
									<InputGroup>
										<Form.Control
											type="number"
											placeholder="軽減税率"
											name="1"
											className="js-data"
											defaultValue={Setting['Basic'][1]['value'] * 100}
											required
										/>
										<InputGroup.Text>%</InputGroup.Text>
									</InputGroup>
								</Col>
							</Form.Group>
							<Button variant="primary" type="submit">
								変更
							</Button>
						</Form>
					) : (
						''
					)}
				</Tab>
				<Tab eventKey="income" title="収入元" className={TabClassName}>
					<Form noValidate validated={Validated} onSubmit={addIncome} className="mb-3">
						<Row>
							<Form.Label column sm="auto">
								新規収入元
							</Form.Label>
							<Col>
								<Input type="text" maxLength={100} required placeholder={'収入元'} />
							</Col>
							<Col sm="auto">
								<Button variant="success" type="submit" className="icon-add__white">
									追加
								</Button>
							</Col>
						</Row>
					</Form>
					{Setting['Income'] ? (
						<Form noValidate validated={Validated}>
							<ListGroup>
								{Setting['Income'].map((income, idx) => (
									<ListGroup.Item key={createKey()}>
										<Row>
											<Col>
												<Input
													type="text"
													maxLength={100}
													defaultValue={income['name']}
													required
													placeholder={'収入元'}
													className="js-data"
												/>
											</Col>
											<Col sm="auto">
												<ButtonGroup>
													<Button
														type="button"
														variant="success"
														name="button"
														value="edit"
														className="icon-edit__white"
														data-id={income['SettingIncomeId']}
														data-idx={idx}
														onClick={editIncome}
													>
														変更
													</Button>
													<Button
														type="button"
														variant="danger"
														name="button"
														value="delete"
														className="icon-trash__white"
														data-id={income['SettingIncomeId']}
														data-idx={idx}
														onClick={editIncome}
													>
														削除
													</Button>
												</ButtonGroup>
											</Col>
										</Row>
									</ListGroup.Item>
								))}
							</ListGroup>
						</Form>
					) : (
						''
					)}
				</Tab>
				<Tab eventKey="payment" title="支払い方法" className={TabClassName}>
					<Form noValidate validated={Validated} onSubmit={addPayment} className="mb-3">
						<Row>
							<Form.Label column sm="2" md="2" lg="1">
								新規支払い方法
							</Form.Label>
							<Col>
								<Input
									type="text"
									maxLength={100}
									name="name"
									required
									placeholder={'支払い方法'}
									className="js-data"
								/>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm="2" md="2" lg="1">
								引き落とし
							</Form.Label>
							<Col sm="auto">
								<Form.Select name="accounted" className="js-data mb-2" onChange={changeAccounted}>
									<option value={0}>即日(現金等)</option>
									{[...Array(32).keys()].map((i) => {
										if (i) {
											return (
												<option value={i} key={createKey()}>
													{i}日
												</option>
											);
										}
									})}
								</Form.Select>
							</Col>
							<Col sm="auto" className="setting-payment__accounted-term mb-2">
								<InputGroup>
									<InputGroup.Text>集計開始日</InputGroup.Text>
									<Form.Select name="from_day" className="js-data">
										{[...Array(32).keys()].map((i) => {
											if (i) {
												return (
													<option value={i} key={createKey()}>
														{i}日
													</option>
												);
											}
										})}
									</Form.Select>
								</InputGroup>
							</Col>
							<Col sm="auto" className="setting-payment__accounted-term mb-2">
								<InputGroup>
									<InputGroup.Text>集計終了日</InputGroup.Text>
									<Form.Select name="to_day" className="js-data">
										{[...Array(32).keys()].map((i) => {
											if (i) {
												return (
													<option value={i} key={createKey()}>
														{i}日
													</option>
												);
											}
										})}
									</Form.Select>
								</InputGroup>
							</Col>
							<Col className="text-end">
								<Button variant="success" type="submit" className="icon-add__white">
									追加
								</Button>
							</Col>
						</Row>
					</Form>
					{Setting['Payment'] ? (
						<Form noValidate validated={Validated} className="mb-3">
							<ListGroup>
								{Setting['Payment'].map((payment, idx) => (
									<ListGroup.Item key={createKey()}>
										<Row>
											<Form.Label column sm="2" md="2" lg="1">
												支払い方法
											</Form.Label>
											<Col>
												<Input
													type="text"
													maxLength={100}
													name="name"
													required
													placeholder={'支払い方法'}
													className="js-data"
													defaultValue={payment['name']}
												/>
											</Col>
										</Row>
										<Row>
											<Form.Label column sm="2" md="2" lg="1">
												引き落とし
											</Form.Label>
											<Col sm="auto" className={payment['accounted'] ? 'setting-payment__accounted' : ''}>
												<Form.Select
													name="accounted"
													className="js-data mb-2"
													defaultValue={payment['accounted']}
													onChange={changeAccounted}
												>
													<option value={0}>即日(現金等)</option>
													{[...Array(32).keys()].map((i) => {
														if (i) {
															return (
																<option value={i} key={createKey()}>
																	{i}日
																</option>
															);
														}
													})}
												</Form.Select>
											</Col>
											<Col sm="auto" className="setting-payment__accounted-term mb-2">
												<InputGroup>
													<InputGroup.Text>集計開始日</InputGroup.Text>
													<Form.Select name="from_day" className="js-data" defaultValue={payment['from_day']}>
														{[...Array(32).keys()].map((i) => {
															if (i) {
																return (
																	<option value={i} key={createKey()}>
																		{i}日
																	</option>
																);
															}
														})}
													</Form.Select>
												</InputGroup>
											</Col>
											<Col sm="auto" className="setting-payment__accounted-term mb-2">
												<InputGroup>
													<InputGroup.Text>集計終了日</InputGroup.Text>
													<Form.Select name="to_day" className="js-data" defaultValue={payment['to_day']}>
														{[...Array(32).keys()].map((i) => {
															if (i) {
																return (
																	<option value={i} key={createKey()}>
																		{i}日
																	</option>
																);
															}
														})}
													</Form.Select>
												</InputGroup>
											</Col>
											<Col className="text-end">
												<ButtonGroup>
													<Button
														variant="success"
														type="button"
														className="icon-edit__white"
														value="edit"
														data-id={payment['PaymentId']}
														data-idx={idx}
														onClick={editPayment}
													>
														編集
													</Button>
													<Button
														variant="danger"
														type="button"
														className="icon-trash__white"
														value="delete"
														data-id={payment['PaymentId']}
														data-idx={idx}
														onClick={editPayment}
													>
														削除
													</Button>
												</ButtonGroup>
											</Col>
										</Row>
									</ListGroup.Item>
								))}
							</ListGroup>
						</Form>
					) : (
						''
					)}
				</Tab>
				<Tab eventKey="category" title="カテゴリー" className={TabClassName}>
					<Form noValidate validated={Validated} onSubmit={addCategory} className="mb-3">
						<Row>
							<Form.Label column sm="2" md="2" lg="1">
								新規カテゴリー
							</Form.Label>
							<Col>
								<Input
									type="text"
									maxLength={100}
									name="name"
									required
									placeholder={'カテゴリー'}
									className="js-data"
								/>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm="2" md="2" lg="1">
								備考
							</Form.Label>
							<Col>
								<Input as="textarea" maxLength={250} name="note" placeholder={'備考'} className="js-data" />
							</Col>
							<Col sm="auto" className="text-end">
								<Button variant="success" type="submit" className="m-0 mt-auto icon-add__white">
									追加
								</Button>
							</Col>
						</Row>
					</Form>
					{Setting['Category'] ? (
						<Form noValidate validated={Validated} className="mb-3">
							<ListGroup>
								{Setting['Category'].map((category, idx) => (
									<ListGroup.Item key={createKey()}>
										<Row>
											<Form.Label column sm="2" md="2" lg="1">
												新規カテゴリー
											</Form.Label>
											<Col>
												<Input
													type="text"
													maxLength={100}
													name="name"
													required
													placeholder={'カテゴリー'}
													className="js-data"
													defaultValue={category['name']}
												/>
											</Col>
										</Row>
										<Row>
											<Form.Label column sm="2" md="2" lg="1">
												備考
											</Form.Label>
											<Col>
												<Input
													as="textarea"
													maxLength={250}
													name="note"
													placeholder={'備考'}
													className="js-data"
													defaultValue={category['note']}
												/>
											</Col>
											<Col sm="auto" className="text-end">
												<ButtonGroup>
													<Button
														variant="success"
														type="button"
														className="icon-edit__white"
														value="edit"
														data-id={category['CategoryId']}
														data-idx={idx}
														onClick={editCategory}
													>
														編集
													</Button>
													<Button
														variant="danger"
														type="button"
														className="icon-trash__white"
														value="delete"
														data-id={category['CategoryId']}
														data-idx={idx}
														onClick={editCategory}
													>
														削除
													</Button>
												</ButtonGroup>
											</Col>
										</Row>
									</ListGroup.Item>
								))}
							</ListGroup>
						</Form>
					) : (
						''
					)}
				</Tab>
				<Tab eventKey="item" title="支出品目" className={TabClassName}>
					<Form noValidate validated={Validated} onSubmit={addItem} className="mb-3">
						<Row>
							<Form.Label column sm="2" md="2" lg="1">
								新規支出品目
							</Form.Label>
							<Col>
								<Input type="text" maxLength={100} name="name" required placeholder={'支出品目'} className="js-data" />
							</Col>
						</Row>
						<Row className="mb-3">
							<Form.Label column sm="2" md="2" lg="1">
								カテゴリー
							</Form.Label>
							<Col sm="auto">
								<Form.Select name="CategoryId" className="js-data" required>
									{Setting['Category'].map((cat) => (
										<option value={cat['CategoryId']} key={createKey()}>
											{cat['name']}
										</option>
									))}
								</Form.Select>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm="2" md="2" lg="1">
								金額
							</Form.Label>
							<Col sm="auto" className="mb-3">
								<InputGroup>
									<Form.Control type="number" name="price" required placeholder={'金額'} className="js-data" />
									<InputGroup.Text>円</InputGroup.Text>
								</InputGroup>
							</Col>
							<Form.Label column sm="auto">
								消費税
							</Form.Label>
							<Col sm="auto" className="mb-3">
								<InputGroup className="me-3">
									<InputGroup.Text>税率</InputGroup.Text>
									<Form.Select name="tax" className="js-data">
										<option value={0}>標準税率</option>
										<option value={1}>軽減税率</option>
									</Form.Select>
								</InputGroup>
							</Col>
							<Col sm="auto" className="mb-3">
								<InputGroup className="me-3">
									<InputGroup.Text>税込表示</InputGroup.Text>
									<Form.Select name="taxIncluded" className="js-data">
										<option value={true}>税込表示</option>
										<option value={false}>税抜表示</option>
									</Form.Select>
								</InputGroup>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm="2" md="2" lg="1">
								備考
							</Form.Label>
							<Col>
								<Input as="textarea" maxLength={250} name="note" placeholder={'備考'} className="js-data" />
							</Col>
							<Col sm="auto" className="text-end">
								<Button variant="success" type="submit" className="m-0 mt-auto icon-add__white">
									追加
								</Button>
							</Col>
						</Row>
					</Form>
					{Setting['Item'] ? (
						<Form noValidate validated={Validated} className="mb-3">
							<Accordion defaultActiveKey={0}>
								{Setting['Category'].map((cat) => (
									<Accordion.Item eventKey={cat['CategoryId']} key={createKey()}>
										<Accordion.Header>{cat['name']}</Accordion.Header>
										<Accordion.Body>
											<ListGroup>
												{Setting['Item'][cat['CategoryId']] &&
													Setting['Item'][cat['CategoryId']].map((item, idx) => (
														<ListGroup.Item key={createKey()}>
															<Row>
																<Form.Label column sm="2" md="2" lg="1">
																	支出品目
																</Form.Label>
																<Col>
																	<Input
																		type="text"
																		maxLength={100}
																		name="name"
																		required
																		placeholder={'支出品目'}
																		className="js-data"
																		defaultValue={item['name']}
																	/>
																</Col>
															</Row>
															<Row className="mb-3">
																<Form.Label column sm="2" md="2" lg="1">
																	カテゴリー
																</Form.Label>
																<Col sm="auto">
																	<Form.Select
																		name="CategoryId"
																		className="js-data"
																		defaultValue={item['CategoryId']}
																		required
																	>
																		{Setting['Category'].map((cat) => (
																			<option value={cat['CategoryId']} key={createKey()}>
																				{cat['name']}
																			</option>
																		))}
																	</Form.Select>
																</Col>
															</Row>
															<Row>
																<Form.Label column sm="2" md="2" lg="1">
																	金額
																</Form.Label>
																<Col sm="auto" className="mb-3">
																	<InputGroup>
																		<Form.Control
																			type="number"
																			name="price"
																			required
																			placeholder={'金額'}
																			className="js-data"
																			defaultValue={item['price']}
																		/>
																		<InputGroup.Text>円</InputGroup.Text>
																	</InputGroup>
																</Col>
																<Form.Label column sm="auto">
																	消費税
																</Form.Label>
																<Col sm="auto" className="mb-3">
																	<InputGroup className="me-3">
																		<InputGroup.Text>税率</InputGroup.Text>
																		<Form.Select name="tax" className="js-data" defaultValue={item['tax']}>
																			<option value={0}>標準税率</option>
																			<option value={1}>軽減税率</option>
																		</Form.Select>
																	</InputGroup>
																</Col>
																<Col sm="auto" className="mb-3">
																	<InputGroup className="me-3">
																		<InputGroup.Text>税込表示</InputGroup.Text>
																		<Form.Select
																			name="taxIncluded"
																			className="js-data"
																			defaultValue={item['taxIncluded']}
																		>
																			<option value={true}>税込表示</option>
																			<option value={false}>税抜表示</option>
																		</Form.Select>
																	</InputGroup>
																</Col>
															</Row>
															<Row>
																<Form.Label column sm="2" md="2" lg="1">
																	備考
																</Form.Label>
																<Col>
																	<Input
																		as="textarea"
																		maxLength={250}
																		name="note"
																		placeholder={'備考'}
																		className="js-data"
																		defaultValue={item['note']}
																	/>
																</Col>
																<Col sm="auto" className="text-end">
																	<ButtonGroup>
																		<Button
																			variant="success"
																			type="button"
																			className="m-0 mt-auto icon-edit__white"
																			data-id={item['ItemId']}
																			data-idx={idx}
																			data-cat={item['CategoryId']}
																			onClick={editItem}
																		>
																			編集
																		</Button>
																		<Button
																			variant="danger"
																			type="button"
																			className="m-0 mt-auto icon-trash__white"
																			data-id={item['ItemId']}
																			data-idx={idx}
																			data-cat={item['CategoryId']}
																			onClick={editItem}
																		>
																			削除
																		</Button>
																	</ButtonGroup>
																</Col>
															</Row>
														</ListGroup.Item>
													))}
											</ListGroup>
										</Accordion.Body>
									</Accordion.Item>
								))}
							</Accordion>
						</Form>
					) : (
						''
					)}
				</Tab>
				<Tab eventKey="store" title="支出先" className={TabClassName}>
					<Form noValidate validated={Validated} onSubmit={addStore} className="mb-3">
						<Row>
							<Form.Label column sm="2" md="2" lg="1">
								新規支出先
							</Form.Label>
							<Col>
								<Input type="text" maxLength={100} name="name" required placeholder={'支出先'} className="js-data" />
							</Col>
						</Row>
						<Row className="mb-3">
							<Form.Label column sm="2" md="2" lg="1">
								支払い方法
							</Form.Label>
							<Col sm="auto">
								<Form.Select name="PaymentId" className="js-data" required>
									{Setting['Payment'].map((pay) => (
										<option value={pay['PaymentId']} key={createKey()}>
											{pay['name']}
										</option>
									))}
								</Form.Select>
							</Col>
							<Col sm="auto" className="mb-3">
								<InputGroup className="me-3">
									<InputGroup.Text>税込表示</InputGroup.Text>
									<Form.Select name="taxIncluded" className="js-data">
										<option value={true}>税込表示</option>
										<option value={false}>税抜表示</option>
									</Form.Select>
								</InputGroup>
							</Col>
						</Row>
						<Row>
							<Form.Label column sm="2" md="2" lg="1">
								備考
							</Form.Label>
							<Col>
								<Input as="textarea" maxLength={250} name="note" placeholder={'備考'} className="js-data" />
							</Col>
							<Col sm="auto" className="text-end">
								<Button variant="success" type="submit" className="m-0 mt-auto icon-add__white">
									追加
								</Button>
							</Col>
						</Row>
					</Form>
					{Setting['Store'] ? (
						<Form noValidate validated={Validated}>
							<ListGroup>
								{Setting['Store'].map((store, idx) => (
									<ListGroup.Item key={createKey()}>
										<Row>
											<Form.Label column sm="2" md="2" lg="1">
												支出先
											</Form.Label>
											<Col>
												<Input
													type="text"
													maxLength={100}
													name="name"
													required
													placeholder={'支出先'}
													className="js-data"
													defaultValue={store['name']}
												/>
											</Col>
										</Row>
										<Row className="mb-3">
											<Form.Label column sm="2" md="2" lg="1">
												支払い方法
											</Form.Label>
											<Col sm="auto">
												<Form.Select name="PaymentId" className="js-data" defaultValue={store['PaymentId']} required>
													{Setting['Payment'].map((pay) => (
														<option value={pay['PaymentId']} key={createKey()}>
															{pay['name']}
														</option>
													))}
												</Form.Select>
											</Col>
											<Col sm="auto" className="mb-3">
												<InputGroup className="me-3">
													<InputGroup.Text>税込表示</InputGroup.Text>
													<Form.Select name="taxIncluded" className="js-data" defaultValue={store['taxIncluded']}>
														<option value={true}>税込表示</option>
														<option value={false}>税抜表示</option>
													</Form.Select>
												</InputGroup>
											</Col>
										</Row>
										<Row>
											<Form.Label column sm="2" md="2" lg="1">
												備考
											</Form.Label>
											<Col>
												<Input as="textarea" maxLength={250} name="note" placeholder={'備考'} defaultValue={store['note']} className="js-data" />
											</Col>
											<Col sm="auto" className="text-end">
												<ButtonGroup>
													<Button variant="success" type="button" className="m-0 mt-auto icon-edit__white" value="edit" data-id={store['StoreId']} data-idx={idx} onClick={editStore}>
														編集
													</Button>
													<Button variant="danger" type="button" className="m-0 mt-auto icon-trash__white" value="delete" data-id={store['StoreId']} data-idx={idx} onClick={editStore}>
														削除
													</Button>
												</ButtonGroup>
											</Col>
										</Row>
									</ListGroup.Item>
								))}
							</ListGroup>
						</Form>
					) : (
						''
					)}
				</Tab>
			</Tabs>
		</>
	);
}
