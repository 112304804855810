import { useContext, useRef, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap';
import AccountsContext from '../../common/contexts/AccountsContext';
import InfoContext, { ErrorContext } from '../../common/contexts/InfoContext';
import LoadingContext from '../../common/contexts/LoadingContext';
import { createKey, number_format, price_format } from '../../components/functional';
import Fetch from '../../components/functional/Fetch';
import setMessage from '../../components/functional/setMessage';
import SettingContext from '../../custom/contexts/SettingContext';
import Input from '../../components/ui-elements/Input';
import ConvertText from '../../components/ui-elements/ConvertText';

export default function SpendEntry({ Validated, setValidated, EditSpend, setEditSpend, setItems, Items }) {
	const Accounts = useContext(AccountsContext);
	const Info = useContext(InfoContext);
	const Error = useContext(ErrorContext);
	const Loading = useContext(LoadingContext);
	const Setting = useContext(SettingContext);

	const [SpendItem, setSpendItem] = useState(false); //支出品目モーダル
	const [ItemCategory, setItemCategory] = useState(false); //支出品目カテゴリー選択
	const [PaymentModal, setPaymanetModal] = useState(false); //支払い設定用モーダル
	const [CouponModal, setCouponModal] = useState(false); //減額設定用モーダル

	const setMes = new setMessage(Info, Error, Loading);

	const StoreRef = useRef();
	const DateRef = useRef();
	const ItemCountRef = useRef();
	const NoteRef = useRef();

	//Modal
	const ItemItemRef = useRef();
	const ItemPriceRef = useRef();
	const ItemNumberRef = useRef();
	const ItemTaxRef = useRef();
	const ItemTacIncludedRef = useRef();

	//Payment Modal
	const PaymentRef = useRef();
	const PaymentPriceRef = useRef();
	const PaymentNoteRef = useRef();

	//Coupon Modal
	const CouponTypeRef = useRef();
	const CouponPriceRef = useRef();
	const CouponNoteRef = useRef();

	/**
	 * 支払先変更
	 *
	 * @param {*} e
	 */
	function changeStore(e) {
		let newItem = Object.assign({}, EditSpend);
		newItem['StoreId'] = e.target.value;
		if (EditSpend['payments'][0]['price'] === '') {
			newItem['payments'][0]['PaymentId'] = Setting['StoreObject'][e.target.value]['PaymentId'];
		}
		setEditSpend(Object.assign({}, newItem));
	}

	/**
	 * 支出編集画面終了
	 *
	 * @param {boolean} [check=true]
	 * @return {*}
	 */
	function closeEditSpend(check = true) {
		if (check) {
			if (!window.confirm('本当に閉じてもよろしいですか？')) {
				return;
			}
		}
		setEditSpend(false);
		return;
	}
	/**
	 * 支出品目追加
	 *
	 */
	function addSpendItem() {
		let newItem = Object.assign({}, EditSpend);
		for (let i = 0; i < ItemCountRef.current.value; ++i) {
			newItem['items'] = newItem['items'].concat({
				SpendItemId: 'new',
				SpendId: '',
				ItemId: '',
				price: '',
				number: 1,
				tax: Setting['Basic'][0]['value'],
				taxIncluded: true,
			});
		}
		setEditSpend(Object.assign({}, newItem));
	}
	/**
	 * 支出品目の編集
	 *
	 * @param {*} e
	 */
	function editSpendItem(e) {
		openEditSpendItem(e.target.value);
	}
	/**
	 * 支出品目モーダル表示
	 *
	 * @param {*} idx
	 */
	function openEditSpendItem(idx) {
		setItemCategory(
			Setting['ItemObject'][EditSpend['items'][idx]]
				? Setting['ItemObject'][EditSpend['items'][idx]]['CategoryId']
				: Setting['Category'][0]['CategoryId']
		);
		setSpendItem(Object.assign(EditSpend['items'][idx], { item_id: idx }));
	}
	/**
	 * 支出品目削除
	 *
	 * @param {*} e
	 */
	function deleteSpendItem(e) {
		let newItem = Object.assign({}, EditSpend);
		if (newItem['items'][e.target.value]['SpendItemId'] === 'new') {
			newItem['items'].splice(e.target.value, 1);
		} else {
			newItem['items'][e.target.value]['status'] = false;
		}
		setEditSpend(newItem);
	}

	/**
	 * 支出品目編集中止
	 *
	 * @param {boolean} [check=true]
	 * @return {*}
	 */
	function closeEditSpendItem(check = true) {
		if (check) {
			if (!window.confirm('本当に閉じてもよろしいですか？')) {
				return;
			}
		}
		setSpendItem(false);
		return;
	}
	/**
	 * 支出品目カテゴリー選択
	 *
	 * @param {*} e
	 */
	function changeCategory(e) {
		setItemCategory(e.target.value);
	}
	/**
	 * 支出品目選択
	 *
	 * @param {*} e
	 */
	function changeItem(e) {
		if (ItemPriceRef.current.value === '') {
			ItemPriceRef.current.value = Setting['ItemObject'][e.target.value]['price'];
			ItemTaxRef.current.value = Setting['Basic'][Setting['ItemObject'][e.target.value]['tax']]['value'];
			ItemTacIncludedRef.current.value = Setting['ItemObject'][e.target.value]['taxIncluded'];
		}
	}
	/**
	 * 支出品目編集終了
	 *
	 */
	function doneSpendItem() {
		let newItem = EditSpend['items'].concat();
		newItem[SpendItem['item_id']] = Object.assign(newItem[SpendItem['item_id']], {
			ItemId: ItemItemRef.current.value,
			price: Number(ItemPriceRef.current.value),
			number: Number(ItemNumberRef.current.value),
			tax: Number(ItemTaxRef.current.value),
			taxIncluded: ItemTacIncludedRef.current.value === 'true',
		});
		setEditSpend(Object.assign(EditSpend, { items: newItem }));
		closeEditSpendItem(false);
	}

	//支払い
	/**
	 * 支払い編集
	 *
	 * @param {*} e
	 */
	function openPaymentModal(e) {
		if (e.target.value === 'new') {
			setPaymanetModal({
				SpendPaymentId: '',
				SpendId: '',
				PaymentId: Setting['Payment'][0]['PaymentId'],
				price: '',
				note: '',
				idx: 'new',
			});
		}
		setPaymanetModal({ ...EditSpend['payments'][e.target.value], idx: e.target.value });
	}
	/**
	 * 支払いモーダル閉じる
	 *
	 * @param {boolean} [check=true]
	 * @return {*}
	 */
	function closePaymentModal(check = true) {
		if (!check) {
			setPaymanetModal(false);
			return;
		}
		if (window.confirm('本当に閉じてもよろしいですか？')) {
			setPaymanetModal(false);
		}
	}
	/**
	 * 支払い登録
	 *
	 */
	function donePayment() {
		let newPayment = {
			...PaymentModal,
			PaymentId: PaymentRef.current.value,
			price: Number(PaymentPriceRef.current.value),
			note: PaymentNoteRef.current.value,
		};
		delete newPayment['idx'];
		let newItem = Object.assign({}, EditSpend);
		if (PaymentModal['idx'] === 'new') {
			newItem['payments'] = newItem['payments'].concat(newPayment);
		} else {
			newItem['payments'][PaymentModal['idx']] = newPayment;
		}
		setEditSpend(Object.assign({}, newItem));
		closePaymentModal(false);
	}
	/**
	 * 支払い削除
	 *
	 * @param {*} e
	 */
	function deletePayment(e) {
		if (window.confirm('本当に削除してもよろしいですか？')) {
			let newItem = Object.assign(EditSpend, {});
			if (EditSpend['payments'][e.target.value]['SpendPaymentId']) {
				newItem['payments'][e.target.value]['status'] = false;
			} else {
				newItem['payments'].splice(e.target.value, 1);
			}
			setEditSpend((item) => Object.assign(item, newItem));
		}
	}

	//減額
	/**
	 * 減額編集
	 *
	 * @param {*} e
	 */
	function openCouponModal(e) {
		setCouponModal(
			Object.assign(
				{ idx: e.target.value },
				e.target.value === 'new'
					? {
							SpendCouponId: '',
							SpendId: '',
							type: 0,
							price: '',
							note: '',
					  }
					: EditSpend['coupons'][e.target.value]
			)
		);
	}
	/**
	 * 減額モーダル閉じる
	 *
	 * @param {boolean} [check=true]
	 * @return {*}
	 */
	function closeCouponModal(check = true) {
		if (!check) {
			setCouponModal(false);
			return;
		}
		if (window.confirm('本当に閉じてもよろしいですか？')) {
			setCouponModal(false);
		}
	}
	/**
	 * 減額変更
	 *
	 */
	function doneCoupon() {
		let newCoupon = {
			...CouponModal,
			type: CouponTypeRef.current.value,
			price: Number(CouponPriceRef.current.value),
			note: CouponNoteRef.current.value,
		};
		delete newCoupon['idx'];
		let newItem = Object.assign({}, EditSpend);
		if (CouponModal['idx'] === 'new') {
			newItem['coupons'] = newItem['coupons'].concat(newCoupon);
		} else {
			newItem['coupons'][CouponModal['idx']] = newCoupon;
		}
		setEditSpend(Object.assign({}, newItem));
		closeCouponModal(false);
	}
	/**
	 * 減額削除
	 *
	 * @param {*} e
	 */
	function deleteCoupon(e) {
		if (window.confirm('本当に削除してもよろしいですか？')) {
			let newItem = Object.assign(EditSpend, {});
			if (EditSpend['coupons'][e.target.value]['SpendCouponId']) {
				newItem['coupons'][e.target.value]['status'] = false;
			} else {
				newItem['coupons'].splice(e.target.value, 1);
			}
			setEditSpend((item) => Object.assign(item, newItem));
		}
	}

	//計算
	/**
	 * 小計計算
	 *
	 * @param {*} item
	 * @return {*}
	 */
	function sumPrice(item) {
		if (item['taxIncluded']) {
			return item['price'] * item['number'];
		}
		return item['price'] * (1 + item['tax']) * item['number'];
	}
	/**
	 * 合計計算
	 *
	 * @return {*}
	 */
	function caluculatePrice() {
		let price = 0;
		EditSpend['items'].map((item) => {
			price += sumPrice(item);
		});
		return price;
	}
	/**
	 * 登録内容送信
	 *
	 * @param {*} e
	 * @return {*}
	 */
	function submitSpend(e) {
		e.preventDefault();
		Loading['Init'](2);
		if (!e.target.checkValidity()) {
			//バリデーションエラー
			setValidated(true);
			Loading['setLoading'](false);
			return false;
		}
		Loading['AddProcess']();
		//送信準備
		let price = 0;
		EditSpend['payments'].map((payment) => {
			price += payment['price'];
		});
		let send = {
			accountId: Accounts['CurrentAccount']['AccountId'],
			body: {},
			method: 'POST',
			path: `account/spend`,
			body: {
				StoreId: StoreRef.current.value,
				payments: EditSpend['payments'],
				coupons: EditSpend['coupons'],
				date: DateRef.current.value,
				price: price,
				note: NoteRef.current.value,
				items: EditSpend['items'],
			},
		};
		if (EditSpend['SpendId'] !== 'new') {
			send['method'] = 'PUT';
			send['path'] += `/${EditSpend['SpendId']}`;
		}
		Fetch(send)
			.then((data) => {
				if (data['result']) {
					setMes['addSuccess']({ title: (EditSpend['SpendId'] === 'new' ? '登録' : '変更') + '完了' });
					let newItem = Object.assign({}, Items);
					if (EditSpend['SpendId'] !== 'new') {
						newItem['spends'][EditSpend['idx']] = data['payloads'];
					} else {
						newItem['spends'] = [data['payloads']].concat(newItem['spends']);
					}
					setItems(Object.assign(Items, newItem));
					closeEditSpend(false);
				} else {
					setMes['addError'](data['error']);
				}
			})
			.catch((error) => {
				setMes['addNetError'](error);
			});
	}
	/**
	 * 支出削除
	 *
	 * @param {*} e
	 * @return {*}
	 */
	function deleteSpend(e) {
		e.preventDefault();
		Loading['Init'](2);
		if (window.confirm('この支出を本当に削除してもよろしいですか？')) {
			Loading['AddProcess']();
			//送信準備
			let send = {
				accountId: Accounts['CurrentAccount']['AccountId'],
				method: 'DELETE',
				path: `account/spend/${EditSpend['SpendId']}`,
			};
			Fetch(send)
				.then((data) => {
					if (data['error']) {
						setMes['addError'](data['error']);
					} else {
						setMes['addSuccess']({ title: '削除完了' });
						let newItem = Object.assign({}, Items);
						Items['spends'].splice(EditSpend['idx'], 1);
						setItems(Object.assign({}, newItem));
						closeEditSpend(false);
					}
				})
				.catch((error) => {
					setMes['addNetError'](error);
				});
		}
		Loading['setLoading'](false);
		return;
	}
	return (
		<Form noValidate validated={Validated} onSubmit={submitSpend}>
			<Row>
				<Form.Label column sm="2" className="mb-2">
					日付
				</Form.Label>
				<Col sm="auto" className="mb-2">
					<Form.Control type="date" defaultValue={EditSpend['date']} required ref={DateRef} />
				</Col>
			</Row>
			<Row>
				<Form.Label column sm="2" className="mb-2">
					支払先
				</Form.Label>
				<Col sm="auto" className="mb-2">
					<Form.Select value={EditSpend['StoreId']} onChange={changeStore} ref={StoreRef}>
						{Setting['Store'].map((store) => (
							<option value={store['StoreId']} key={createKey()}>
								{store['name']}
							</option>
						))}
					</Form.Select>
				</Col>
			</Row>
			<Row>
				<Form.Label column sm="2" className="mb-2">
					支出品目
				</Form.Label>
				<Col className="mb-2">
					<Table striped hover responsive>
						<thead>
							<tr>
								<td>No.</td>
								<td>品目</td>
								<td></td>
							</tr>
						</thead>
						<tbody>
							{
								//登録支出品目
								EditSpend['items'].map((item, idx) => (
									<tr key={createKey()}>
										<td>{idx + 1}</td>
										<td>
											{item['ItemId'] ? (
												Setting['ItemObject'][item['ItemId']]['name']
											) : (
												<span className="text-danger pe-3">編集で登録してください</span>
											)}
											@{price_format(item['price'])}×{number_format(item['number'])}({item['tax'] * 100}%
											{item['taxIncluded'] ? '税込' : '税別'})=
											{price_format(sumPrice(item))}
										</td>
										<td>
											<Button
												type="button"
												value={idx}
												variant="success"
												className="icon-edit__white"
												onClick={editSpendItem}
											>
												編集
											</Button>
											<Button variant="danger" className="icon-trash__white ms-2" value={idx} onClick={deleteSpendItem}>
												削除
											</Button>
										</td>
									</tr>
								))
							}
						</tbody>
						<tfoot>
							<tr>
								<th>合計金額</th>
								<td>{price_format(caluculatePrice())}</td>
								<td>
									<Row className="m-0 p-0">
										<Col sm="auto">
											<InputGroup>
												<Form.Control type="number" placeholder="追加数" defaultValue={1} ref={ItemCountRef} />
												<InputGroup.Text>個</InputGroup.Text>
												<Button
													variant="info"
													type="button"
													className="text-white icon-add__white"
													onClick={addSpendItem}
												>
													追加
												</Button>
											</InputGroup>
										</Col>
									</Row>
								</td>
							</tr>
						</tfoot>
					</Table>
					{SpendItem === false ? (
						''
					) : (
						<Modal show={true} onHide={closeEditSpendItem} centered size="lg">
							<Modal.Header closeButton>支出品目登録</Modal.Header>
							<Modal.Body>
								<Row>
									<Form.Label column sm="2" className="mb-2">
										支出品目カテゴリー
									</Form.Label>
									<Col sm="auto" className="mb-2">
										<Form.Select
											defaultValue={SpendItem['ItemId'] ? Setting['ItemObject'][SpendItem['ItemId']]['CategoryId'] : ''}
											onChange={changeCategory}
										>
											{Setting['Category'].map((cat) => (
												<option key={createKey()} value={cat['CategoryId']}>
													{cat['name']}
												</option>
											))}
										</Form.Select>
									</Col>
									<Form.Label column sm="auto" className="mb-2">
										支出品目
									</Form.Label>
									<Col sm="auto" className="mb-2" onChange={changeItem}>
										<Form.Select defaultValue={SpendItem['ItemId']} ref={ItemItemRef}>
											{ItemCategory &&
												Setting['Item'][ItemCategory].map((item) => (
													<option key={createKey()} value={item['ItemId']}>
														{item['name']}
													</option>
												))}
										</Form.Select>
									</Col>
								</Row>
								<Row>
									<Form.Label column sm="auto" className="mb-2">
										金額
									</Form.Label>
									<Col sm="auto" className="mb-2">
										<InputGroup>
											<InputGroup.Text>@</InputGroup.Text>
											<Form.Control
												type="number"
												placeholder="金額"
												defaultValue={SpendItem['price']}
												required
												ref={ItemPriceRef}
											/>
										</InputGroup>
									</Col>
									<Col sm="auto" className="mb-2">
										×
									</Col>
									<Col sm="auto" className="mb-2">
										<InputGroup>
											<Form.Control
												type="number"
												placeholder="個数"
												defaultValue={SpendItem['number']}
												ref={ItemNumberRef}
												required
											/>
											<InputGroup.Text>個</InputGroup.Text>
										</InputGroup>
									</Col>
								</Row>
								<Row>
									<Form.Label column sm="auto" className="mb-2">
										消費税
									</Form.Label>
									<Col sm="auto" className="mb-2">
										<Form.Select defaultValue={SpendItem['tax']} ref={ItemTaxRef}>
											{Object.keys(Setting['BasicObject']).map((key) => (
												<option key={createKey()} value={Setting['BasicObject'][key]['value']}>
													{Setting['BasicObject'][key]['value'] * 100}%
												</option>
											))}
										</Form.Select>
									</Col>
									<Col sm="auto" className="mb-2">
										<Form.Select defaultValue={SpendItem['taxIncluded']} ref={ItemTacIncludedRef}>
											<option value={true}>税込</option>
											<option value={false}>税別</option>
										</Form.Select>
									</Col>
								</Row>
							</Modal.Body>
							<Modal.Footer>
								<Button variant="primary" type="button" className="icon-edit__white" onClick={doneSpendItem}>
									変更
								</Button>
							</Modal.Footer>
						</Modal>
					)}
				</Col>
			</Row>
			<Row>
				<Form.Label column sm="2" className="mb-2">
					支払い
				</Form.Label>
				<Col sm="auto" className="mb-2">
					<Table responsive striped>
						<thead>
							<tr>
								<td>支払い方法</td>
								<td>支払金額</td>
								<td>備考</td>
								<td></td>
							</tr>
						</thead>
						<tbody>
							{EditSpend['payments'].map((payment, idx) => (
								<tr key={createKey()}>
									<td>
										{payment['PaymentId'] ? (
											Setting['PaymentObject'][payment['PaymentId']]['name']
										) : (
											<span className="text-danger pe-3">編集で登録してください</span>
										)}
									</td>
									<td>{price_format(payment['price'])}</td>
									<td>
										<ConvertText>{payment['note']}</ConvertText>
									</td>
									<td>
										<Button
											variant="success"
											type="button"
											value={idx}
											className="icon-edit__white"
											onClick={openPaymentModal}
										>
											編集
										</Button>
										<Button
											variant="danger"
											type="button"
											value={idx}
											className="ms-2 icon-trash__white"
											onClick={deletePayment}
										>
											編集
										</Button>
									</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={3}></td>
								<td>
									<Button
										variant="info"
										type="button"
										value="new"
										className="text-white icon-add__white"
										onClick={openPaymentModal}
									>
										追加
									</Button>
								</td>
							</tr>
						</tfoot>
					</Table>
				</Col>
				{PaymentModal === false ? (
					''
				) : (
					<Modal show={true} onHide={closePaymentModal} centered size="lg">
						<Modal.Header closeButton>支払い登録</Modal.Header>
						<Modal.Body>
							<Row>
								<Form.Label column sm="2" className="mb-2">
									支払い方法
								</Form.Label>
								<Col className="mb-2">
									<Form.Select defaultValue={PaymentModal['PaymentId']} ref={PaymentRef}>
										{Setting['Payment'].map((pay) => (
											<option value={pay['PaymentId']} key={createKey()}>
												{pay['name']}
											</option>
										))}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label column sm="2" className="mb-2">
									金額
								</Form.Label>
								<Col className="mb-2">
									<InputGroup>
										<Form.Control
											type="number"
											defaultValue={PaymentModal['price']}
											required
											placeholder="金額"
											ref={PaymentPriceRef}
										/>
										<InputGroup.Text>円</InputGroup.Text>
									</InputGroup>
								</Col>
							</Row>
							<Row>
								<Form.Label column sm="2">
									備考
								</Form.Label>
								<Col>
									<Input
										as="textarea"
										placeholder="備考"
										defaultValue={PaymentModal['note']}
										maxLength="250"
										ref={PaymentNoteRef}
									/>
								</Col>
							</Row>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" type="button" className={`icon-edit__white`} onClick={donePayment}>
								変更
							</Button>
						</Modal.Footer>
					</Modal>
				)}
			</Row>
			<Row>
				<Form.Label column sm="2" className="mb-2">
					減額
				</Form.Label>
				<Col sm="auto" className="mb-2">
					<Table responsive striped>
						<thead>
							<tr>
								<td>減額種類</td>
								<td>金額</td>
								<td>備考</td>
								<td></td>
							</tr>
						</thead>
						<tbody>
							{EditSpend['coupons'].map((coupon, idx) => (
								<tr key={createKey()}>
									<td>
										{SpendCouponType[coupon['type']]}
									</td>
									<td>{price_format(coupon['price'])}</td>
									<td>
										<ConvertText>{coupon['note']}</ConvertText>
									</td>
									<td>
										<Button
											variant="success"
											type="button"
											value={idx}
											className="icon-edit__white"
											onClick={openCouponModal}
										>
											編集
										</Button>
										<Button
											variant="danger"
											type="button"
											value={idx}
											className="ms-2 icon-trash__white"
											onClick={deleteCoupon}
										>
											編集
										</Button>
									</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={3}></td>
								<td>
									<Button
										variant="info"
										type="button"
										value="new"
										className="text-white icon-add__white"
										onClick={openCouponModal}
									>
										追加
									</Button>
								</td>
							</tr>
						</tfoot>
					</Table>
				</Col>
				{CouponModal === false ? (
					''
				) : (
					<Modal show={true} onHide={closeCouponModal} centered size="lg">
						<Modal.Header closeButton>支払い登録</Modal.Header>
						<Modal.Body>
							<Row>
								<Form.Label column sm="2" className="mb-2">
									減額種類
								</Form.Label>
								<Col className="mb-2">
									<Form.Select defaultValue={CouponModal['type']} ref={CouponTypeRef}>
										{SpendCouponType.map((coupon,idx) => (
											<option value={idx} key={createKey()}>
												{coupon}
											</option>
										))}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label column sm="2" className="mb-2">
									金額
								</Form.Label>
								<Col className="mb-2">
									<InputGroup>
										<Form.Control
											type="number"
											defaultValue={CouponModal['price']}
											required
											placeholder="金額"
											ref={CouponPriceRef}
										/>
										<InputGroup.Text>円</InputGroup.Text>
									</InputGroup>
								</Col>
							</Row>
							<Row>
								<Form.Label column sm="2">
									備考
								</Form.Label>
								<Col>
									<Input
										as="textarea"
										placeholder="備考"
										defaultValue={CouponModal['note']}
										maxLength="250"
										ref={CouponNoteRef}
									/>
								</Col>
							</Row>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" type="button" className={`icon-edit__white`} onClick={doneCoupon}>
								変更
							</Button>
						</Modal.Footer>
					</Modal>
				)}
			</Row>
			<Row>
				<Form.Label column sm="2" className="mb-2">
					備考
				</Form.Label>
				<Col className="mb-2">
					<Input as="textarea" placeholder="備考" defaultValue={EditSpend['note']} maxLength="250" ref={NoteRef} />
				</Col>
			</Row>
			<Row>
				<Col className="text-end">
					<Button
						variant="primary"
						type="submit"
						className={EditSpend['SpendId'] === 'new' ? 'icon-add__white' : 'icon-edit__white'}
					>
						{EditSpend['SpendId'] === 'new' ? '登録' : '変更'}
					</Button>
					{EditSpend['SpendId'] === 'new' ? (
						''
					) : (
						<Button variant="danger" type="button" className="icon-trash__white ms-2" onClick={deleteSpend}>
							削除
						</Button>
					)}
				</Col>
			</Row>
		</Form>
	);
}

export const SpendCouponType = ["ポイント利用", "クーポン利用","割引"];