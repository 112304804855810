import { useContext} from 'react';
import { Button, ButtonGroup, Col, Form,  Row, Table } from 'react-bootstrap';
import { createKey, date_format, number_format, price_format } from '../../components/functional';
import ConvertText from '../../components/ui-elements/ConvertText';
import PaginationMeta from '../../components/ui-parts/PaginationMeta';
import SettingContext from '../../custom/contexts/SettingContext';

export default function SpendList({Items,Per,changePer,getRecord,editSpend}) {
	const Setting = useContext(SettingContext);

	return (
		<>
			<Row>
				<Col sm="auto" className="mb-3">
					<ButtonGroup>
						<Button variant="success" type="button" className="icon-add__white" value="new" onClick={editSpend}>
							新規支出
						</Button>
					</ButtonGroup>
				</Col>
			</Row>
			{Items && (
				<Row>
					<Col>
						<Row>
							<Col sm="auto" className="ms-3 mb-2 p-0 pe-1">
								<Form.Select value={Per} onChange={changePer} className="d-inline-block">
									<option value={5}>5</option>
									<option value={10}>10</option>
									<option value={100}>100</option>
									<option value={500}>500</option>
								</Form.Select>
							</Col>
							<Form.Label column className="mb-2 p-0 pt-2">
								件 表示
							</Form.Label>
						</Row>
						<Table striped hover responsive>
							<thead>
								<tr>
									<td>No.</td>
									<td>日付</td>
									<td>金額</td>
									<td>支払い方法</td>
									<td>支出品目数</td>
									<td>備考</td>
									<td></td>
								</tr>
							</thead>
							<tbody>
								{Items['spends'].map((spend, idx) => (
									<tr key={createKey()}>
										<td>{idx + 1}</td>
										<td>{date_format(spend['date'], 'Y/n/j')}</td>
										<td>{price_format(spend['price'])}</td>
										<td>{Setting['PaymentObject'][spend['PaymentId']]}</td>
										<td>{number_format(spend['items'].length)}件</td>
										<td>
											<ConvertText children={spend['note']} />
										</td>
										<td>
											<Button
												variant="success"
												type="button"
												className="icon-edit__white"
												value={idx}
												onClick={editSpend}
											>
												編集
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
						<PaginationMeta meta={Items['meta']} onClick={getRecord} />
					</Col>
				</Row>
			)}
		</>
	);
}
