import { Modal } from 'react-bootstrap';
import AccountTable from '../../features/AccountTable';

export default function SwitchAccount({ show = false, onHide = () => {} }) {
	function switchAccount(AccountId){
		localStorage.setItem('account',AccountId);
		onHide();
		window.location.reload();
	}
	return (
		<Modal show={show} onHide={onHide} scrollable centered>
			<Modal.Header>アカウントの変更</Modal.Header>
			<AccountTable switchAccount={switchAccount} action={true} onClick={switchAccount} />
		</Modal>
	);
}
