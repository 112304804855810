import { useEffect, useState } from 'react';

export default function useSetting() {
	const [Basic, setBasic] = useState(); //基本
	const [Income, setIncome] = useState([]); //収入
	const [Payment, setPayment] = useState([]); //支払い方法
	const [Category, setCategory] = useState([]); //カテゴリー
	const [Item, setItem] = useState({}); //支出品目
	const [Store, setStore] = useState([]); //支出先
	const [BasicObject, setBasicObject] = useState({});
	const [IncomeObject, setIncomeObject] = useState({});
	const [PaymentObject, setPaymentObject] = useState({});
	const [CategoryObject, setCategoryObject] = useState({});
	const [ItemObject, setItemObject] = useState({});
	const [StoreObject, setStoreObject] = useState({});
	useEffect(() => {
		setSettingObject(setBasicObject, 'type', Basic);
	}, [Basic]);
	useEffect(() => {
		setSettingObject(setIncomeObject, 'SettingIncomeId', Income);
	}, [Income]);
	useEffect(() => {
		setSettingObject(setPaymentObject, 'PaymentId', Payment);
	}, [Payment]);
	useEffect(() => {
		setSettingObject(setCategoryObject, 'CategoryId', Category);
	}, [Category]);
	useEffect(() => {
    let items = [];
    Object.keys(Item).map((key)=>{
      items = items.concat(Item[key])
    });
		setSettingObject(setItemObject, 'ItemId', items);
	}, [Item]);
	useEffect(() => {
		setSettingObject(setStoreObject, 'StoreId', Store);
	}, [Store]);

	/**
	 * 汎用性設定オブジェクトセット
	 *
	 * @param {*} [setObj=() => {}]
	 * @param {string} [idKey='']
	 * @param {*} [payloads=[]]
	 * @param {*} [items=null]
	 */
	function setSettingObject(setObj = () => {}, idKey = '', payloads = [], items = null) {
		if (items === null) {
			items = {};
			for (let i = 0; i < payloads.length; ++i) {
				items[payloads[i][idKey]] = Object.assign({idx:i},payloads[i]);
			}
		}
		setObj(items);
	}
	return {
		Basic,
		Income,
		Payment,
		Category,
		Item,
		Store,
		BasicObject,
		IncomeObject,
		PaymentObject,
		CategoryObject,
		ItemObject,
		StoreObject,
		setBasic,
		setIncome,
		setPayment,
		setCategory,
		setItem,
		setStore,
	};
}
