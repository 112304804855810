import { useContext, useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import AccountsContext from '../../common/contexts/AccountsContext';
import useFetch from '../../common/hooks/useFetch';
import { createKey } from '../../components/functional';
import AccountListItem from '../../components/ui-parts/AccountListItem';

export default function AccountTable({ onClick = () => {}, action = false }) {
	const Accounts = useContext(AccountsContext);
	const { send } = useFetch();

	useEffect(() => {
		send('user/account?app=kakeibo', (data) => {
			if (data['result']) {
				Accounts['setAccounts'](data['payloads']['accounts']);
			}
		});
	}, []);
	if (Accounts) {
		return (
			<ListGroup>
				{Accounts['Accounts'].map((account, idx) => (
					<ListGroup.Item action={action} key={createKey()}>
						<AccountListItem account={account} onClick={() => onClick(idx)} />
					</ListGroup.Item>
				))}
			</ListGroup>
		);
	}
	return;
}
