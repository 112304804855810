import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import AccountsContext from '../../common/contexts/AccountsContext';
import BreadCrumbContext from '../../common/contexts/BreadCrumbContext';
import InfoContext, { ErrorContext } from '../../common/contexts/InfoContext';
import LoadingContext from '../../common/contexts/LoadingContext';
import { changeTitle, date_format } from '../../components/functional';
import Fetch from '../../components/functional/Fetch';
import setMessage from '../../components/functional/setMessage';
import SettingContext from '../../custom/contexts/SettingContext';
import SpendEntry from './entry';
import SpendList from './list';

export default function Spend() {
	changeTitle('支出');
	const breadCrumb = useContext(BreadCrumbContext);
	const Accounts = useContext(AccountsContext);
	const Info = useContext(InfoContext);
	const Error = useContext(ErrorContext);
	const Loading = useContext(LoadingContext);
	const Setting = useContext(SettingContext);

	const [Items, setItems] = useState(false);
	const [EditSpend, setEditSpend] = useState(false);
	const [Validated, setValidated] = useState(false);
	const [Per, setPer] = useState(10);

	const setMes = new setMessage(Info, Error, Loading);


	useEffect(() => {
		breadCrumb['changeVisible'](true);
		breadCrumb['setItems']([]);
		getRecord(1);
	}, []);

	/**
	 * 支出レコード取得
	 *
	 * @param {number} [page=1]
	 */
	function getRecord(page = 1, per = Per) {
		Loading['Init'](1);
		Fetch({ path: `account/spend?page=${page}&per=${per}`, accountId: Accounts['CurrentAccount']['AccountId'] })
			.then((data) => {
				if (data['result']) {
					setItems(data['payloads']);
					Loading['setLoading'](false);
				} else {
					setMes['addError'](data['error']);
				}
			})
			.catch((error) => {
				setMes['addNetError'](error);
			});
	}
	/**
	 * 取得レコード数変更
	 *
	 * @param {*} e
	 */
	function changePer(e) {
		setPer(e.target.value);
		getRecord(Items['meta']['currentPage'], e.target.value);
	}
	/**
	 *モーダル
	 *
	 * @param {*} e
	 */
	function editSpend(e) {
		setEditSpend(
			Object.assign(
				{
					idx: e.target.value,
				},
				e.target.value === 'new'
					? {
							SpendId: 'new',
							StoreId: '',
							payments: [
								{
									"SpendPaymentId": "",
									"SpendId": "",
									"PaymentId": Setting['Payment'][0]['PaymentId'],
									"price": "",
									"note": ""
								}
							],
							coupons: [],
							date: date_format(null, 'Y-m-d'),
							price: '',
							note: '',
							items: [],
					  }
					: Items['spends'][e.target.value]
			)
		);
	}
	/**
	 * 編集モード終了
	 *
	 */
	function closeSpend(){
		if (window.confirm('編集を中断してもよろしいですか？')) {
			setEditSpend(false);
		}
	}

	return (
		<>
			{EditSpend === false ? (
				<SpendList Items={Items} Per={Per} changePer={changePer} getRecord={getRecord} editSpend={editSpend} />
			) : (
				<Modal show fullscreen onHide={closeSpend}>
					<Modal.Header closeButton>{EditSpend['SpendId'] === 'new' ? '新規支出' : '支出編集'}</Modal.Header>
					<Modal.Body>
						<SpendEntry
							Validated={Validated}
							setValidated={setValidated}
							EditSpend={EditSpend}
							setEditSpend={setEditSpend}
							setItems={setItems}
							Items={Items}
						/>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}
