import { useContext, useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import AccountsContext from '../../common/contexts/AccountsContext';
import BreadCrumbContext from '../../common/contexts/BreadCrumbContext';
import InfoContext, { ErrorContext } from '../../common/contexts/InfoContext';
import LoadingContext from '../../common/contexts/LoadingContext';
import { changeTitle, createKey, date_format, price_format } from '../../components/functional';
import Fetch from '../../components/functional/Fetch';
import setMessage from '../../components/functional/setMessage';
import ConvertText from '../../components/ui-elements/ConvertText';
import PaginationMeta from '../../components/ui-parts/PaginationMeta';
import SettingContext from '../../custom/contexts/SettingContext';

export default function Income() {
	changeTitle('収入');
	const breadCrumb = useContext(BreadCrumbContext);
	const Accounts = useContext(AccountsContext);
	const Info = useContext(InfoContext);
	const Error = useContext(ErrorContext);
	const Loading = useContext(LoadingContext);
	const Setting = useContext(SettingContext);

	const [Items, setItems] = useState(false);
	const [EditIncome, setEditIncome] = useState(false);
	const [Validated, setValidated] = useState(false);
	const [Per, setPer] = useState(10);

	const setMes = new setMessage(Info, Error, Loading);

	const FromRef = useRef();
	const ToRef = useRef();
	const DateRef = useRef();
	const PriceRef = useRef();
	const NoteRef = useRef();

	useEffect(() => {
		breadCrumb['changeVisible'](true);
		breadCrumb['setItems']([]);
		getRecord(1);
	}, []);

	/**
	 * 収入レコード取得
	 *
	 * @param {number} [page=1]
	 */
	function getRecord(page = 1, per = Per) {
		Loading['Init'](1);
		Fetch({ path: `account/income?page=${page}&per=${per}`, accountId: Accounts['CurrentAccount']['AccountId'] })
			.then((data) => {
				if (data['result']) {
					setItems(data['payloads']);
					Loading['setLoading'](false);
				} else {
					setMes['addError'](data['error']);
				}
			})
			.catch((error) => {
				setMes['addNetError'](error);
			});
	}
	function changePer(e) {
		setPer(e.target.value);
		getRecord(Items['meta']['currentPage'], e.target.value);
	}
	/**
	 *モーダル
	 *
	 * @param {*} e
	 */
	function editIncome(e) {
		setEditIncome(
			Object.assign(
				{
					idx:e.target.value
				},
				e.target.value === 'new'
					? {
							IncomeId: 'new',
							from: '',
							to: '',
							date: date_format(null, 'Y-m-d'),
							price: '',
							note: '',
					  }
					: Items['incomes'][e.target.value]
			)
		);
	}
	/**
	 *モーダル閉じる
	 *
	 * @param {boolean} [check=true]
	 * @return {*}
	 */
	function closeEditIncome(check = true) {
		if (check) {
			if (!window.confirm('本当に閉じてもよろしいですか？')) {
				return;
			}
		}
		setEditIncome(false);
		return;
	}
	/**
	 * 登録内容送信
	 *
	 * @param {*} e
	 * @return {*}
	 */
	function submitIncome(e) {
		e.preventDefault();
		Loading['Init'](2);
		if (!e.target.checkValidity()) {
			//バリデーションエラー
			setValidated(true);
			Loading['setLoading'](false);
			return false;
		}
		Loading['AddProcess']();
		//送信準備
		let send = {
			accountId: Accounts['CurrentAccount']['AccountId'],
			body: {
				from: FromRef.current.value,
				to: ToRef.current.value,
				date: DateRef.current.value,
				price: Number(PriceRef.current.value),
				note: NoteRef.current.value,
			},
			method: 'POST',
			path: `account/income`,
		};
		if (EditIncome['IncomeId'] !== 'new') {
			send['method'] = 'PUT';
			send['path'] += EditIncome['IncomeId'];
		}
		Fetch(send)
			.then((data) => {
				if (data['result']) {
					setMes['addSuccess']({ title: (EditIncome['IncomeId'] === 'new' ? '登録' : '変更') + '完了' });
					setItems(Object.assign(Items, { incomes: [data['payloads']].concat(Items['incomes']) }));
					closeEditIncome(false);
				} else {
					setMes['addError'](data['error']);
				}
			})
			.catch((error) => {
				setMes['addNetError'](error);
			});
	}
	/**
	 * 収入削除
	 *
	 * @param {*} e
	 * @return {*}
	 */
	function deleteIncome(e) {
		e.preventDefault();
		Loading['Init'](2);
		if (window.confirm('この収入を本当に削除してもよろしいですか？')) {
			Loading['AddProcess']();
			//送信準備
			let send = {
				accountId: Accounts['CurrentAccount']['AccountId'],
				method: 'DELETE',
				path: `account/income/${EditIncome['IncomeId']}`,
			};
			Fetch(send)
				.then((data) => {
					if (data['error']) {
						setMes['addError'](data['error']);
					} else {
						setMes['addSuccess']({ title: '削除完了' });
						let newItem = Object.assign({}, Items);
						Items['incomes'].splice(EditIncome['idx'], 1);
						setItems(Object.assign({}, newItem));
						closeEditIncome(false);
					}
				})
				.catch((error) => {
					setMes['addNetError'](error);
				});
		}
		Loading['setLoading'](false);
		return;
	}
	return (
		<>
			<Row>
				<Col sm="auto" className="mb-3">
					<ButtonGroup>
						<Button variant="success" type="button" className="icon-add__white" value="new" onClick={editIncome}>
							新規収入
						</Button>
					</ButtonGroup>
				</Col>
			</Row>
			{Items && (
				<Row>
					<Col>
						<Row>
							<Col sm="auto" className="ms-3 mb-2 p-0 pe-1">
								<Form.Select value={Per} onChange={changePer} className="d-inline-block">
									<option value={5}>5</option>
									<option value={10}>10</option>
									<option value={100}>100</option>
									<option value={500}>500</option>
								</Form.Select>
							</Col>
							<Form.Label column className="mb-2 p-0 pt-2">
								件 表示
							</Form.Label>
						</Row>
						<Table striped hover responsive>
							<thead>
								<tr>
									<td>No.</td>
									<td>日付</td>
									<td>金額</td>
									<td>収入元</td>
									<td>収入先</td>
									<td>備考</td>
									<td></td>
								</tr>
							</thead>
							<tbody>
								{Items['incomes'].map((income, idx) => (
									<tr key={createKey()}>
										<td>{idx+1}</td>
										<td>{date_format(income['date'], 'Y/n/j')}</td>
										<td>{price_format(income['price'])}</td>
										<td>{Setting['IncomeObject'][income['from']]['name']}</td>
										<td>{Setting['PaymentObject'][income['to']]['name']}</td>
										<td>
											<ConvertText children={income['note']} />
										</td>
										<td>
											<Button
												variant="success"
												type="button"
												className="icon-edit__white"
												value={idx}
												onClick={editIncome}
											>
												編集
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
						<PaginationMeta meta={Items['meta']} onClick={getRecord} />
					</Col>
				</Row>
			)}
			{EditIncome === false ? (
				''
			) : (
				<Modal show={true} onHide={closeEditIncome} centered>
					<Form noValidate validated={Validated} onSubmit={submitIncome}>
						<Modal.Header closeButton>{EditIncome['IncomeId'] === 'new' ? '新規収入' : '収入編集'}</Modal.Header>
						<Modal.Body>
							<Row>
								<Form.Label column sm="2" className="mb-2">
									日付
								</Form.Label>
								<Col sm="auto" className="mb-2">
									<Form.Control type="date" defaultValue={EditIncome['date']} required ref={DateRef} />
								</Col>
							</Row>
							<Row>
								<Form.Label column sm="2" className="mb-2">
									収入元
								</Form.Label>
								<Col sm="auto" className="mb-2">
									<Form.Select defaultValue={EditIncome['from']} ref={FromRef}>
										{Setting['Income'].map((income) => (
											<option value={income['SettingIncomeId']} key={createKey()}>
												{income['name']}
											</option>
										))}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label column sm="2" className="mb-2">
									収入先
								</Form.Label>
								<Col sm="auto" className="mb-2">
									<Form.Select defaultValue={EditIncome['to']} ref={ToRef}>
										{Setting['Payment'].map((payment) => (
											<option value={payment['PaymentId']} key={createKey()}>
												{payment['name']}
											</option>
										))}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label column sm="2" className="mb-2">
									金額
								</Form.Label>
								<Col sm="auto" className="mb-2">
									<Form.Control
										type="number"
										defaultValue={EditIncome['price']}
										required
										placeholder="金額"
										ref={PriceRef}
									/>
								</Col>
							</Row>
							<Row>
								<Form.Label column sm="2" className="mb-2">
									備考
								</Form.Label>
								<Col sm="auto" className="mb-2">
									<Form.Control as="textarea" defaultValue={EditIncome['note']} placeholder="備考" ref={NoteRef} />
								</Col>
							</Row>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="primary"
								type="submit"
								className={EditIncome['IncomeId'] === 'new' ? 'icon-add__white' : 'icon-edit__white'}
							>
								{EditIncome['IncomeId'] === 'new' ? '登録' : '変更'}
							</Button>
							{EditIncome['IncomeId'] === 'new' ? (
								''
							) : (
								<Button variant="danger" type="button" className="icon-trash__white" onClick={deleteIncome}>
									削除
								</Button>
							)}
						</Modal.Footer>
					</Form>
				</Modal>
			)}
		</>
	);
}
