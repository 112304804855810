import { useContext, useEffect, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import BreadCrumbContext from '../../../common/contexts/BreadCrumbContext';
import InfoContext, { ErrorContext } from '../../../common/contexts/InfoContext';
import { createKey } from '../../functional';
import BreadCrumb from '../../ui-parts/BreadCrumb';

export default function Main(props) {
	const info = useContext(InfoContext);
	const error = useContext(ErrorContext);
	const breadCrumb = useContext(BreadCrumbContext);
	return (
		<Container fluid {...props} className="p-3">
			{breadCrumb['Visible'] ? <BreadCrumb items={breadCrumb['ItemObjects']} /> : ""}
			<InfoBox message={info} />
			<InfoBox message={error} />
			{props.children}
			<div style={{height:"60px"}}></div>
		</Container>
	);
}

function InfoBox({ message }) {
	const [info, setInfo] = useState(message);
	useEffect(() => {
		setInfo(message);
	}, [message]);
	function ConvertErrorTitle({ MessageObject }) {
		if (MessageObject['abstract'] || MessageObject['title'] || MessageObject['code']) {
			return (
				<div className="fs-4">
					{MessageObject['abstract'] ? <span className="me-2 fw-bold">[{MessageObject['abstract']}]</span> : ''}
					{MessageObject['title'] ? <span className='fw-bolder'>{MessageObject['title']}</span> : ''}
					{MessageObject['code'] ? <span className="ms-2 fs-6">(code:{MessageObject['code']})</span> : ''}
				</div>
			);
		}
		return <></>;
	}
	if (info['MessageObject'] === null) {
		return <></>;
	}
	return (
		<>
			{info['MessageObject'] ? (
				<InfoAlert
					title={<ConvertErrorTitle MessageObject={info['MessageObject']} />}
					type={info['MessageObject']['type']}
					message={info['MessageObject']['message']}
					onClose={info['closeMessage']}
					timeout={info['MessageObject']['timeout']}
				/>
			) : (
				''
			)}
		</>
	);
}
function InfoAlert({ title, message, type, close = true, onClose = () => {}, timeout = 0 }) {
	const [Show, setShow] = useState(true);
	function Close() {
		setShow(false);
		setTimeout(onClose, 1000);
	}
	function ConvertMessage({ message, parent = null }) {
		if (message === null) {
			return <></>;
		}
		if (Array.isArray(message)) {
			return (
				<ul>
					{message.map((mes) => (
						<ConvertMessage message={mes} parent="ul" key={createKey()} />
					))}
				</ul>
			);
		}
		if (typeof message === 'object') {
			return (
				<ul>
					{Object.keys(message).map((key) => (
						<ConvertMessage message={isNaN(key)?[key,[message[key]]]:message[key]} parent="ul" key={createKey()} />
					))}
				</ul>
			);
		}
		if (parent === 'ul') {
			return <li>{message}</li>;
		}
		return <div>{message}</div>;
	}
	useEffect(() => {
		let timeSet = setTimeout(Close, timeout);
		if (timeout === 0) {
			clearTimeout(timeSet);
		}
	}, [timeout]);
	return (
		<Alert show={Show} variant={type}>
			<div className="position-relative">
				{title ? <div className="fs-3">{title}</div> : ''}
				{close ? (
					<div className="position-absolute end-0 top-0">
						<button type="button" className="btn-close" aria-label="Close" onClick={Close}></button>
					</div>
				) : (
					''
				)}
			</div>
			<ConvertMessage message={message} />
		</Alert>
	);
}
