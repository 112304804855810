import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { changeTitle } from '..';
import { AppName } from '../../../common/constants';
import Home from '../../../pages';
import Error404 from '../../../pages/error/404';
import Income from '../../../pages/income';
import Setting from '../../../pages/setting';
import Spend from '../../../pages/spend';
import Transfer from '../../../pages/transfer';
import LoadMd from '../../ui-elements/LoadMd';
import VerisonMd from './../../../common/assets/version.md';

export default function Router() {
	return (
		<Routes>
			<Route path="/">
				<Route index element={<Home />} />
				<Route path='version' element={<VersionShow />} />
				<Route path='setting' element={<Setting />} />
				<Route path='income'>
					<Route index element={<Income />} />
				</Route>
				<Route path='spend'>
					<Route index element={<Spend />} />
				</Route>
				<Route path='transfer'>
					<Route index element={<Transfer />} />
				</Route>
			</Route>
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
}

function VersionShow() {
	changeTitle(`${AppName}情報`)
	const [Md,setMd] = useState("");

	useEffect(()=>{
		fetch(VerisonMd).then((response) => {
			return response.text();
		}).then((data)=>{
			setMd(data);
		})
	},[])
	return <LoadMd markdown={Md} />;
}