import { useContext, useEffect, useRef, useState } from 'react';
import BreadCrumbContext from '../common/contexts/BreadCrumbContext';
import { changeTitle, date_format, number_format, price_format } from '../components/functional';
import AccountsContext from '../common/contexts/AccountsContext';
import InfoContext, { ErrorContext } from '../common/contexts/InfoContext';
import LoadingContext from '../common/contexts/LoadingContext';
import setMessage from '../components/functional/setMessage';
import SettingContext from '../custom/contexts/SettingContext';
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import useFetch from '../common/hooks/useFetch';

export default function Home() {
	changeTitle('');
	const breadCrumb = useContext(BreadCrumbContext);
	const Accounts = useContext(AccountsContext);
	const Info = useContext(InfoContext);
	const Error = useContext(ErrorContext);
	const Loading = useContext(LoadingContext);
	const Setting = useContext(SettingContext);

	const { send } = useFetch();

	const setMes = new setMessage(Info, Error, Loading);

	const [Report, setReport] = useState();

	const YearMonthRef = useRef();

	useEffect(() => {
		breadCrumb['setItems']([]);
		getRecord('account/report');
	}, []);

	/**
	 * データ取得
	 *
	 * @param {string} path
	 * @param {boolean|string} [query=true]
	 * @return {*}
	 */
	function getRecord(path, query = true) {
		if (query) {
			let YearMonth = YearMonthRef.current.value.split('-');
			if (YearMonth.length < 2) {
				return false;
			}
			query = '?' + (query === true ? '' : `${query}&`) + `year=${YearMonth[0]}&month=${YearMonth[1]}`;
		}
		send(`${path}${query ? query : ''}`,(data) => {
			if (data['error']) {
				return;
			}
			setReport(data['payloads']);
		})
	}
	function actionFilter(e) {
		e.preventDefault();
		Loading['Init'](1);
		getRecord('account/report');
	}
	return (
		<>
			<Row>
				<Col sm="auto">
					<Form onSubmit={actionFilter}>
						<InputGroup>
							<Form.Control type="month" defaultValue={date_format(null, 'Y-m')} ref={YearMonthRef} />
							<Button variant="secondary" type="submit">
								取得
							</Button>
						</InputGroup>
					</Form>
				</Col>
			</Row>
			{Report ? (
				<div className="mt-4">
					<h3>収支サマリー({date_format(Report['filter']['date'], 'Y年n月')})</h3>
					<Row className="my-3">
						<Col sm="auto">
							<Table responsive striped>
								<tbody>
									<tr>
										<th>収入</th>
										<td>
											{price_format(Report['income']['price'])}({number_format(Report['income']['count'])}件)
										</td>
									</tr>
									<tr>
										<th>支出</th>
										<td>
											{price_format(Report['spend']['price'])}({number_format(Report['spend']['count'])}件)
										</td>
									</tr>
									<tr>
										<th>移動</th>
										<td>
											{price_format(Report['transfer']['price'])}({number_format(Report['transfer']['count'])}件)
										</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</div>
			) : (
				''
			)}
		</>
	);
}
