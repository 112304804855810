import { createKey } from '..';
import { __API__ } from '../../../common/configs/config';

/**
 *
 *
 * @export
 * @param {*} { accountId = 0, path = '', method = 'GET', body = [], bodyType="JSON", headers={}, version = 1 }
 * @return {*}
 */
export default function Fetch({
	accountId = 0,
	path = '',
	method = 'GET',
	body = [],
	bodyType = 'JSON',
	headers = {},
	version = 1,
}) {
	let send = {
		method: 'POST',
		headers: Object.assign(
			{
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Licsss-Account': accountId,
				'Licsss-Path': path,
				'Licsss-Method': method,
				'Licsss-Nonce': createKey(),
				'Licsss-Version': version,
				'X-XSRF-TOKEN': sessionStorage.getItem('XSRF-TOKEN'),
			},
			headers
		),
		credentials: 'include', //__DEV__?'include':'same-origin', //本番はsame-origin
	};
	if (method !== 'GET') {
		send['body'] = bodyType === 'JSON' ? JSON.stringify(body) : body;
	}
	return new Promise((resolve, reject) => {
		fetch(`${__API__}`, send)
			.then((res) => {
				if (!res.ok) {
					//throw new Error(res.statusText);
				}
				if (res.status !== 204){
					return res.json();
				}
				return {
					nonce: send['headers']['Licsss-Nonce']
				};
			})
			.then((res) => {
				if (res['nonce'] === send['headers']['Licsss-Nonce']) {
					return resolve(res);
				}
				return reject(res);
			})
			.catch((error) => {
				return reject(error);
			});
	});
}
