import { createContext, useContext, useEffect, useState } from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import { BrowserRouter } from 'react-router-dom';
import AccountsContext from './common/contexts/AccountsContext';
import BreadCrumbContext from './common/contexts/BreadCrumbContext';
import InfoContext, { ErrorContext } from './common/contexts/InfoContext';
import LoadingContext from './common/contexts/LoadingContext';
import SettingContext from './custom/contexts/SettingContext'; //設定ページ向け配列
import useAccounts from './common/hooks/useAccounts';
import useBreadCumrb from './common/hooks/useBreadCrumb';
import useInfo from './common/hooks/useInfo';
import useLoading from './common/hooks/useLoading';
import useSetting from './custom/hooks/useSetting';
import Fetch from './components/functional/Fetch';
import Router from './components/functional/Router';
import Footer from './components/layouts/Footer';
import Header from './components/layouts/Header';
import Main from './components/layouts/Main';
import setMessage from './components/functional/setMessage';

export const UserContext = createContext();

export default function App({ init }) {
	const accounts = useAccounts();
	const loading = useLoading();
	const info = useInfo();
	const error = useInfo();
	const breadCrumb = useBreadCumrb();
	const setting = useSetting();
	//return <></>;
	return (
		<BrowserRouter basename="/">
			<div className="position-relative" style={{ minHeight: '100vh' }}>
				<UserContext.Provider value={init['user']}>
					<AccountsContext.Provider value={accounts}>
						<Header />
						<AccountsContextGet account={init['accounts']}>
							<LoadingContext.Provider value={loading}>
								<InfoContext.Provider value={info}>
									<ErrorContext.Provider value={error}>
										<BreadCrumbContext.Provider value={breadCrumb}>
											<SettingContext.Provider value={setting}>
												{accounts['Accounts'].length ? <Home setting={init['settings']} /> : ''}
											</SettingContext.Provider>
										</BreadCrumbContext.Provider>
									</ErrorContext.Provider>
								</InfoContext.Provider>
							</LoadingContext.Provider>
						</AccountsContextGet>
						<Footer />
					</AccountsContext.Provider>
				</UserContext.Provider>
			</div>
		</BrowserRouter>
	);
}

function Home({setting}) {
	const Setting = useContext(SettingContext);
	const Accounts = useContext(AccountsContext);
	const Info = useContext(InfoContext);
	const Error = useContext(ErrorContext);

	const [Status, setStatus] = useState([]);
	let setMes = new setMessage(Info, Error);

	useEffect(() => {
		Setting['setBasic'](setting['basic']);
		Setting['setIncome'](setting['income']);
		Setting['setPayment'](setting['payment']);
		Setting['setStore'](setting['store']);
		Setting['setCategory'](setting['category']);
		Setting['setItem'](setting['item']);
		setStatus(Status.concat('setting'));
	}, []);
	return (
		<Loading>
			{Status.length === 1 ? (
				<Main>
					<Router />
				</Main>
			) : (
				''
			)}
		</Loading>
	);
}

//Loading
function Loading({ children }) {
	const loading = useContext(LoadingContext);
	return (
		<>
			{loading['Loading'] ? (
				<Modal show={true} centered>
					<Modal.Body>
						<div className="fs-3 mb-3">通信中...</div>
						<ProgressBar>
							<ProgressBar
								variant="success"
								label={`${loading['CurrentProcess']}/${loading['AllProcess']}`}
								animated
								now={loading['CurrentProcess']}
								max={loading['AllProcess']}
							/>
							<ProgressBar
								variant="light"
								animated
								now={loading['AllProcess'] - loading['CurrentProcess']}
								max={loading['AllProcess']}
							/>
						</ProgressBar>
					</Modal.Body>
				</Modal>
			) : (
				''
			)}
			{children}
		</>
	);
}

//アカウントcontext
function AccountsContextGet({ children, account }) {
	const Accounts = useContext(AccountsContext);
	useEffect(() => {
		if (Accounts['Accounts'].length === 0) {
			Accounts['setAccounts'](account);
			if (!account[localStorage.getItem('account')]) {
				localStorage.setItem('account', 0);
			}
			Accounts['setCurrentAccount'](account[localStorage.getItem('account')]);
		}
	}, []);
	return children;
}
