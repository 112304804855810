import { Button, Col, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import AccountsContext from '../../common/contexts/AccountsContext';
import BreadCrumbContext from '../../common/contexts/BreadCrumbContext';
import InfoContext, { ErrorContext } from '../../common/contexts/InfoContext';
import LoadingContext from '../../common/contexts/LoadingContext';
import { changeTitle, createKey, date_format, price_format } from '../../components/functional';
import Fetch from '../../components/functional/Fetch';
import Input from '../../components/ui-elements/Input';
import setMessage from '../../components/functional/setMessage';
import SettingContext from '../../custom/contexts/SettingContext';
import ConvertText from '../../components/ui-elements/ConvertText';
import PaginationMeta from '../../components/ui-parts/PaginationMeta';

export default function Transfer() {
	changeTitle('移動');
	const breadCrumb = useContext(BreadCrumbContext);
	const Accounts = useContext(AccountsContext);
	const Info = useContext(InfoContext);
	const Error = useContext(ErrorContext);
	const Loading = useContext(LoadingContext);
	const Setting = useContext(SettingContext);
	const setMes = new setMessage(Info, Error, Loading);

	const [Validated, setValidated] = useState(false);
	const [Items, setItems] = useState(false); //一覧取得
	const [Per, setPer] = useState(10); //取得件数
	const [TransferModal, setTransferModal] = useState(false); //編集モーダル

	useEffect(() => {
		breadCrumb['changeVisible'](true);
		breadCrumb['setItems']([]);
	}, []);
  useEffect(()=>{
    getRecord();
  },[Per])

	/**
	 * 一覧取得
	 *
	 * @param {number} [page=1]
	 * @param {*} [per=Per]
	 */
	function getRecord(page = 1, per = Per) {
		Loading['Init'](1);
		Fetch({ path: `account/transfer?page=${page}&per=${per}`, accountId: Accounts['CurrentAccount']['AccountId'] })
			.then((data) => {
				if (data['result']) {
					setItems(data['payloads']);
					Loading['setLoading'](false);
				} else {
					setMes['addError'](data['error']);
				}
			})
			.catch((error) => {
				setMes['addNetError'](error);
			});
	}
	/**
	 * 取得件数変更
	 *
	 * @param {*} e
	 */
	function changePer(e) {
		setPer(e.target.value);
	}
	/**
	 * 編集モーダル表示
	 *
	 * @param {*} e
	 */
	function openTransferModal(e) {
		setValidated(false);
		setTransferModal(
			Object.assign(
				{ idx: e.target.value },
				e.target.value === 'new'
					? {
							TransferId: '',
							from: '',
							to: '',
							date: date_format(null, 'Y-m-d'),
							price: '',
							note: '',
					  }
					: Items['transfers'][e.target.value]
			)
		);
	}
	/**
	 * モーダル閉じる
	 *
	 * @param {boolean} [check=true]
	 */
	function closeTranferModal(check = true) {
		if ((check && window.confirm('編集を中断してもよろしいですか？')) || !check) {
			setTransferModal(false);
			setValidated(false);
		}
	}
	/**
	 * 移動の登録・変更
	 *
	 * @param {*} e
	 * @return {*}
	 */
	function actionSubmit(e) {
		e.preventDefault();
		Loading['Init'](3);
		if (!e.target.checkValidity()) {
			//バリデーションエラー
			setValidated(true);
			Loading['setLoading'](false);
			return false;
		}
		Loading['AddProcess']();
		let send = {
			path: 'account/transfer',
			method: 'POST',
			body: {},
			accountId: Accounts['CurrentAccount']['AccountId'],
		};
		for (let i = 0; i < e.target.length; ++i) {
			if (e.target[i].name) {
				send['body'][e.target[i].name] = e.target[i].name === 'price' ? Number(e.target[i].value) : e.target[i].value;
			}
		}
		if (TransferModal['idx'] !== 'new') {
			send['path'] += `/${TransferModal['TransferId']}`;
			send['method'] = 'PUT';
		}
		Loading['AddProcess']();
		Fetch(send)
			.then((data) => {
				if (data['error']) {
					setMes['addError'](data['error']);
					return;
				}
				let newItem = Object.assign({}, Items);
				if (TransferModal['idx'] === 'new') {
					newItem['transfers'] = [data['payloads']].concat(newItem['transfers']);
				} else {
					newItem['transfers'][TransferModal['idx']] = data['payloads'];
				}
				console.log(newItem);
				setItems(Object.assign({}, newItem));
				setMes['addSuccess']();
				closeTranferModal(false);
			})
			.catch((error) => {
				setMes['addNetError'](error);
				closeTranferModal(false);
			});
	}
	/**
	 * 移動削除
	 *
	 * @return {*}
	 */
	function actionDelete() {
		Loading['Init'](3);
		if (TransferModal['idx'] === 'new') {
			window.alert('削除できません');
			return;
		}
		if (!window.confirm('本当に削除してもよろしいですか？')) {
			Loading['setLoading'](false);
			return;
		}
		Loading['AddProcess']();
		let send = {
			path: `account/transfer/${TransferModal['TransferId']}`,
			method: 'DELETE',
			accountId: Accounts['CurrentAccount']['AccountId'],
		};
		Loading['AddProcess']();
		Fetch(send)
			.then((data) => {
				if (data['error']) {
					setMes['addError'](data['error']);
					return;
				}
				let newItem = Object.assign({}, Items);
				newItem['transfers'].splice(TransferModal['idx'], 1);
				setItems(Object.assign({}, newItem));
				setMes['addSuccess']();
				closeTranferModal(false);
			})
			.catch((error) => {
				setMes['addNetError'](error);
				closeTranferModal(false);
			});
	}
	return (
		<>
			<Row>
				<Col sm="auto" className="mb-3">
					<Button variant="success" type="button" className="icon-add__white" value="new" onClick={openTransferModal}>
						新規移動
					</Button>
				</Col>
			</Row>
			<Row>
				<Col sm="auto" className="ms-3 mb-2 p-0 pe-1">
					<Form.Select value={Per} onChange={changePer} className="d-inline-block">
						<option value={5}>5</option>
						<option value={10}>10</option>
						<option value={100}>100</option>
						<option value={500}>500</option>
					</Form.Select>
				</Col>
				<Form.Label column className="mb-2 p-0 pt-2">
					件 表示
				</Form.Label>
			</Row>
			<Row>
				{Items ? (
					<Col>
						<Table responsive striped hover>
							<thead>
								<tr>
									<td>No.</td>
									<td>移動日</td>
									<td>移動元</td>
									<td>移動先</td>
									<td>金額</td>
									<td>備考</td>
									<td></td>
								</tr>
							</thead>
							<tbody>
								{Items['transfers'].map((transfer, idx) => (
									<tr key={createKey()}>
										<td>{idx + 1}</td>
										<td>{date_format(transfer['date'], 'Y/n/j')}</td>
										<td>{Setting['PaymentObject'][transfer['from']]['name']}</td>
										<td>{Setting['PaymentObject'][transfer['to']]['name']}</td>
										<td>{price_format(transfer['price'])}</td>
										<td>
											<ConvertText>{transfer['note']}</ConvertText>
										</td>
										<td>
											<Button
												variant="success"
												type="button"
												className="icon-edit__white"
												onClick={openTransferModal}
												value={idx}
											>
												編集
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
						<PaginationMeta meta={Items['meta']} onClick={getRecord} />
					</Col>
				) : (
					''
				)}
			</Row>
			{TransferModal === false ? (
				''
			) : (
				<Modal show onHide={closeTranferModal} centered size="lg">
					<Form noValidate validated={Validated} onSubmit={actionSubmit}>
						<Modal.Header closeButton>{TransferModal['idx'] === 'new' ? '新規移動' : '移動編集'}</Modal.Header>
						<Modal.Body>
							<Row>
								<Form.Label column sm="2" className="mb-2">
									移動日
								</Form.Label>
								<Col sm="auto">
									<Form.Control
										type="date"
										className="mb-2"
										defaultValue={TransferModal['date']}
										placeholder="移動日"
										name="date"
										required
									/>
								</Col>
							</Row>
							<Row>
								<Form.Label column sm="2" className="mb-2">
									移動元
								</Form.Label>
								<Col sm="auto">
									<Form.Select name="from" defaultValue={TransferModal['from']} required>
										{Setting['Payment'].map((payment) => (
											<option key={createKey()} value={payment['PaymentId']}>
												{payment['name']}
											</option>
										))}
									</Form.Select>
								</Col>
								<Form.Label column sm="auto" className="mb-2">
									<span className="me-3">⇒</span>
									移動先
								</Form.Label>
								<Col sm="auto">
									<Form.Select name="to" defaultValue={TransferModal['to']} required>
										{Setting['Payment'].map((payment) => (
											<option key={createKey()} value={payment['PaymentId']}>
												{payment['name']}
											</option>
										))}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label column sm="2" className="mb-2">
									金額
								</Form.Label>
								<Col sm="auto">
									<InputGroup>
										<Form.Control
											type="number"
											name="price"
											defaultValue={TransferModal['price']}
											placeholder="金額"
											required
										/>
										<InputGroup.Text>円</InputGroup.Text>
									</InputGroup>
								</Col>
							</Row>
							<Row>
								<Form.Label column sm="2" className="mb-2">
									備考
								</Form.Label>
								<Col>
									<Input
										as="textarea"
										name="note"
										defaultValue={TransferModal['note']}
										placeholder="備考"
										maxLength={250}
									/>
								</Col>
							</Row>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="primary"
								type="submit"
								className={`icon-${TransferModal['idx'] === 'new' ? 'add' : 'edit'}__white`}
							>
								{TransferModal['idx'] === 'new' ? '追加' : '変更'}
							</Button>
							{TransferModal['idx'] === 'new' ? (
								''
							) : (
								<Button variant="danger" type="button" className="icon-trash__white ms-2" onClick={actionDelete}>
									削除
								</Button>
							)}
						</Modal.Footer>
					</Form>
				</Modal>
			)}
		</>
	);
}
