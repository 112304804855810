import { useNavigate } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { __LOGOUT__, __URL__, __USER__ } from '../../../common/configs/config';
import { useContext, useState } from 'react';
import AccountsContext from '../../../common/contexts/AccountsContext';
import SwitchAccount from '../../../templates/SwitchAccount';
import Icon32 from '../../ui-elements/Icon32';
import { AppName } from '../../../common/constants';

export default function Header() {
	const Accounts = useContext(AccountsContext);
	const [SwitchAccountShow, setSwitchAccountShow] = useState(false);
	const navigate = useNavigate();
	function clickLink(e) {
		e.preventDefault();
		navigate(e.target.pathname);
	}
	function switchAccount(e) {
		e.preventDefault();
		setSwitchAccountShow(true);
	}
	function closeSwitchAccount() {
		setSwitchAccountShow(false);
	}
	return (
		<>
			<Navbar as="header" role="banner" bg="light" expand="lg">
				<Container fluid>
					<Navbar.Brand href={__URL__} onClick={clickLink}>
						{AppName}
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="_header-nav">
						<Nav className="ms-auto">
							<Nav.Link href="/" onClick={clickLink}>
								HOME
							</Nav.Link>
							<Nav.Link href="/income" onClick={clickLink}>
								収入
							</Nav.Link>
							<Nav.Link href="/spend" onClick={clickLink}>
								支出
							</Nav.Link>
							<Nav.Link href="/transfer" onClick={clickLink}>
								移動
							</Nav.Link>
							{/*<Nav.Link href="/report" onClick={clickLink}>
								レポート
	</Nav.Link>*/}
							<Nav.Link href="/setting" onClick={clickLink}>
								設定
							</Nav.Link>
							<NavDropdown
								title={
									<>
										<span>{Accounts['CurrentAccount']['DisplayName']}</span>
										<Icon32
											src={
												Accounts['CurrentAccount']['AccountIcon']
													? Accounts['CurrentAccount']['AccountIcon']['icons']['IconLink'] + 'tiny'
													: ''
											}
										/>
									</>
								}
								id="_header-account-navDropdown"
								align="end"
								className="w-auto p-0"
							>
								<NavDropdown.Item href={'#'} onClick={switchAccount}>
									アカウントの切り替え
								</NavDropdown.Item>
								<NavDropdown.Item href={__USER__} target="_blank">
									アカウント
								</NavDropdown.Item>
								<NavDropdown.Item href={__LOGOUT__}>
									ログアウト
								</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<SwitchAccount show={SwitchAccountShow} onHide={setSwitchAccountShow} />
		</>
	);
}
