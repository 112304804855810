import { AppName, Day, Month, Year } from '../../common/constants';

/**
 * ページタイトル更新
 *
 * @export
 * @param {*} title
 */
export function changeTitle(title) {
	document.title = (title ? `${title} | ` : '') + AppName;
}

/**
 * ランダム文字列生成
 *
 * @export
 * @return {*}
 */
export function createKey() {
	return Math.random().toString(32).substring(2);
}

/**
 * cookie取得
 *
 * @export
 * @param {*} [key=null]
 * @return {*}
 */
export function cookies(key = null) {
	let cookies = {};
	document.cookie.split('; ').map((cookie) => {
		let tmp = cookie.split('=');
		cookies[tmp[0]] = decodeURIComponent(tmp[1]);
	});
	if (key === null) {
		return cookies;
	}
	return cookies[key];
}

/**
 * 数値カンマ区切り
 *
 * @export
 * @param {*} number
 * @return {*}
 */
export function number_format(number) {
	return Number(number).toLocaleString(undefined, { maximumFractionDigits: 20 });
}

/**
 * 金額カンマ区切り，記号付け
 *
 * @export
 * @param {*} price
 * @return {*}
 */
export function price_format(price) {
	return Number(price).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' });
}

/**
 * 表示用日付変更
 *
 * @export
 * @param {*} date
 * @param {string} [format="Y/n/j H:i:s"]
 * @return {*}
 */
export function date_format(date, format = 'Y/n/j H:i:s') {
	if (!date) {
		date = new Date();
	}
	if (date['date']) {
		date = String(date['date']);
	}
	if (date['year'] || date['month'] || date['day']) {
		date = String(
			`${date['year'] ? date['year'] : Year}-${date['month'] ? date['month'] : Month}-${
				date['day'] ? date['day'] : Day
			}`
		);
	}
	const dateObj = isString(date) ? new Date(date) : date;
	const Y = dateObj.getFullYear();
	const n = Number(dateObj.getMonth()) + 1;
	const m = ('00' + n).slice(-2);
	const j = dateObj.getDate();
	const d = ('00' + j).slice(-2);
	const H = dateObj.getHours();
	const i = dateObj.getMinutes();
	const s = dateObj.getSeconds();
	return format
		.replace('Y', Y)
		.replace('n', n)
		.replace('j', j)
		.replace('m', m)
		.replace('d', d)
		.replace('H', H)
		.replace('i', i)
		.replace('s', s);
}

export function isString(value) {
	if (typeof value === 'string' || value instanceof String) {
		return true;
	} else {
		return false;
	}
}
