import { Pagination } from 'react-bootstrap';
import { createKey } from '../../functional';

export default function PaginationMeta({ meta, onClick = (page) => {} }) {
	let pages = [].concat(meta['visiblePage'][0]).concat(meta['visiblePage'][1]).concat(meta['visiblePage'][2]);
	function clickPage(e) {
		let page = e.target.dataset.page;
		if (page === undefined) {
			page = e.target.parentNode.dataset.page;
		}
		onClick(page);
	}
	return (
		<div>
			<Pagination>
				<Pagination.First disabled={meta['currentPage'] === 1} data-page={1} onClick={clickPage} />
				<Pagination.Prev disabled={meta['currentPage'] === 1} data-page={meta['currentPage'] - 1} onClick={clickPage} />
				{meta['visiblePage'][0].map((num) => (
					<Pagination.Item active={num === meta['currentPage']} data-page={num} onClick={clickPage} key={createKey()}>
						{num}
					</Pagination.Item>
				))}

				{pages.indexOf(1) > -1 ? '' : <Pagination.Ellipsis disabled />}

				{meta['visiblePage'][1].map((num) => (
					<Pagination.Item active={num === meta['currentPage']} data-page={num} onClick={clickPage} key={createKey()}>
						{num}
					</Pagination.Item>
				))}

				{pages.indexOf(meta['lastPage']) > -1 ? '' : <Pagination.Ellipsis disabled />}

				{meta['visiblePage'][2].map((num) => (
					<Pagination.Item active={num === meta['currentPage']} data-page={num} onClick={clickPage} key={createKey()}>
						{num}
					</Pagination.Item>
				))}
				<Pagination.Next
					disabled={meta['currentPage'] === meta['lastPage']}
					data-page={meta['currentPage'] + 1}
					onClick={clickPage}
				/>
				<Pagination.Last
					disabled={meta['currentPage'] === meta['lastPage']}
					data-page={meta['lastPage']}
					onClick={clickPage}
				/>
			</Pagination>
		</div>
	);
}
