//import * as React from 'react';
import { forwardRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import './style.css';

//export default const React.forwardRef(
const Input = forwardRef(
	(
		{
			type = 'text',
			maxLength = null,
			describe = null,
			valid = null,
			invalid = null,
			placeholder = null,
			required = false,
			defaultValue = null,
			onChange = null,
			as = 'input',
			children,
			...args
		},
		ref
	) => {
		const [Cnt, setCnt] = useState(defaultValue === null ? 0 : defaultValue.length);
		if (maxLength === 0) {
			maxLength = null;
		}
		if (maxLength !== null && describe === null) {
			describe = `${maxLength}文字以下で入力してください`;
		}
		if (valid === null) {
			valid = 'OK';
		}
		if (invalid === null) {
			invalid = describe;
		}
		function changeInput(e) {
			setCnt(e.target.value.length);
			if (onChange) {
				onChange(e);
			}
		}
		return (
			<div className="position-relative">
				<Form.Control
					as={as}
					type={type}
					maxLength={maxLength}
					placeholder={placeholder}
					defaultValue={defaultValue}
					required={required}
					onChange={changeInput}
					ref={ref}
					{...args}
				/>
				{valid ? (
					<Form.Control.Feedback>{valid}</Form.Control.Feedback>
				) : (
					<Form.Text className="input-maxLengthHeight__keep" muted>
						{describe}
					</Form.Text>
				)}
				{invalid ? <Form.Control.Feedback type="invalid">{invalid}</Form.Control.Feedback> : ''}
				<Form.Text className="input-maxLengthHeight__keep" muted>
					{describe}
				</Form.Text>
				{maxLength ? (
					<div className="position-absolute bottom-0 end-0">
						<Form.Text>
							{Cnt}/{maxLength}文字
						</Form.Text>
					</div>
				) : (
					''
				)}
			</div>
		);
	}
);
export default Input;
/*
export default function Input({
	type = 'text',
	maxLength = null,
	describe = null,
	valid = null,
	invalid = null,
	placeholder = null,
	required = false,
	defaultValue = null,
	onChange = null,
	as = 'input',
	children,
	ref = null,
	...args
}) {
	const [Cnt, setCnt] = useState(defaultValue === null ? 0 : defaultValue.length);
	if (maxLength === 0) {
		maxLength = null;
	}
	if (maxLength !== null && describe === null) {
		describe = `${maxLength}文字以下で入力してください`;
	}
	if (valid === null) {
		valid = 'OK';
	}
	if (invalid === null) {
		invalid = describe;
	}
	function changeInput(e) {
		setCnt(e.target.value.length);
		if (onChange) {
			onChange(e);
		}
	}
	return (
		<div className="position-relative">
			<Form.Control
				as={as}
				type={type}
				maxLength={maxLength}
				placeholder={placeholder}
				defaultValue={defaultValue}
				required={required}
				onChange={changeInput}
				ref = {ref}
				{...args}
			/>
			{valid ? (
				<Form.Control.Feedback>{valid}</Form.Control.Feedback>
			) : (
				<Form.Text className="input-maxLengthHeight__keep" muted>
					{describe}
				</Form.Text>
			)}
			{invalid ? <Form.Control.Feedback type="invalid">{invalid}</Form.Control.Feedback> : ''}
			<Form.Text className="input-maxLengthHeight__keep" muted>
				{describe}
			</Form.Text>
			{maxLength ? (
				<div className="position-absolute bottom-0 end-0">
					<Form.Text>
						{Cnt}/{maxLength}文字
					</Form.Text>
				</div>
			) : (
				''
			)}
		</div>
	);
}
*/
