import { Col, Row } from 'react-bootstrap';
import Icon32 from '../../ui-elements/Icon32';

export default function AccountListItem(props) {
	return (
		<Row className="p-0 m-0 py-3" onClick={props['onClick']}>
			<Col sm={2}>
				<Icon32 src={props['account']['AccountIcon']['icons']['IconLink']+"tiny"} />
			</Col>
			<Col sm={8}>
        {props['account']['DisplayName']}
      </Col>
		</Row>
	);
}
